import { create } from 'zustand';
import { useEffect } from 'react';
import { useApiTasks } from '../components/subComponents/_fetchTasks';
import { useApiProjects } from '../components/subComponents/_fetchProjects';
import { useApiEvents } from '../components/subComponents/_fetchEvents';
import { useApiContexts } from '../components/subComponents/_fetchContexts';
import { useApiAssignees } from '../components/subComponents/_fetchAssignees';

const useStore = create(set => ({
    tasks: [],
    projects: [],
    events: [],
    contexts: [],
    assignees: [],
    isLoading: false,
    error: null,

    
    setIsLoading: isLoading => set({ isLoading }),
    setError: error => set({ error }),
    
    setTasks: tasks => set({ tasks }),
    addTask: (task) => set(state => ({ tasks: [...state.tasks, task] })),
    updateTask: (updatedTask) => set(state => ({
        tasks: state.tasks.map(task => task.id === updatedTask.id ? updatedTask : task)
    })),
    removeTask: (taskIds) => set(state => {
        const newTasks = state.tasks.filter(task => !taskIds.includes(task.id));
        return { tasks: newTasks };
    }),

    setProjects: projects => set({ projects }),
    addProject: (project) => set(state => ({ projects: [...state.projects, project] })),
    updateProject: (updatedProject) => set(state => ({
        projects: state.projects.map(project => project.id === updatedProject.id ? updatedProject : project)
    })),
    removeProject: (projectIds) => set(state => {
        const newProjects = state.projects.filter(project => !projectIds.includes(project.id));
        return { projects: newProjects };
    }),
    setEvents: events => set({ events }),
    addEvent: (event) => set(state => ({ events: [...state.events, event] })),
    updateEvent: (updatedEvent) => set(state => {
        const newEvents = state.events.map(event => event.id === updatedEvent.id ? updatedEvent : event);
        return { events: newEvents };
    }),
    removeEvent: (eventId) => set(state => {
        const newEvents = state.events.filter(event => !eventId.includes(event.id));
        return { events: newEvents };
    }),

    setContexts: contexts => set({ contexts }),
    addContext: (context) => set(state => ({ contexts: [...state.contexts, context] })),
    updateContext: (updatedContext) => set(state => ({
        contexts: state.contexts.map(context => context.id === updatedContext.id ? updatedContext : context)
    })),
    removeContext: (contextId) => set(state => ({
        contexts: state.contexts.filter(context => context.id !== contextId)
    })),

    setAssignees: assignees => set({ assignees }),
    addAssignee: (assignee) => set(state => ({ assignees: [...state.assignees, assignee] })),
    updateAssignee: (updatedAssignee) => set(state => ({
        assignees: state.assignees.map(assignee => assignee.id === updatedAssignee.id ? updatedAssignee : assignee)
    })),
    removeAssignee: (assigneeId) => set(state => ({
        assignees: state.assignees.filter(assignee => assignee.id !== assigneeId)
    })),
}));


export default useStore;

export const useInitializeData = () => {
    const { fetchTasks } = useApiTasks();
    const { fetchProjectsAPI } = useApiProjects();
    const { fetchEvents } = useApiEvents();
    const { fetchContexts } = useApiContexts();
    const { fetchAssignees } = useApiAssignees();
    const setIsLoading = useStore(state => state.setIsLoading);
    const setError = useStore(state => state.setError);

    const setTasks = useStore(state => state.setTasks);

    const setProjects = useStore(state => state.setProjects);
    const setEvents = useStore(state => state.setEvents);
    const setContexts = useStore(state => state.setContexts);
    const setAssignees = useStore(state => state.setAssignees);

    useEffect(() => {
        const initializeData = async () => {
        setIsLoading(true);
        try {
            const [tasks, projects, events, contexts, assignees] = await Promise.all([

            fetchTasks(),
            fetchProjectsAPI(),

            fetchEvents(),
            fetchContexts(),
            fetchAssignees()
            ]);
            setTasks(tasks);
            setProjects(projects);
            setEvents(events);
            setContexts(contexts);
            setAssignees(assignees);
        } catch (error) {
            console.error("Failed to fetch initial data:", error);
            setError(error);
        } finally {
            setIsLoading(false);

        }
        };


        initializeData();
    }, [fetchTasks, fetchProjectsAPI, fetchEvents, fetchContexts, fetchAssignees, setTasks, setProjects, setEvents, setContexts, setAssignees, setIsLoading, setError]);
};






