import React from 'react';
import styles from './App.module.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import SideMenu from "./components/sidemenu";
import QuickTaskForm from './components/QuickTaskForm';
import NewTaskOrganizer from './components/newTaskOrganizer';
import NextTaskCapture from './components/NextTaskCapture';
import PrioritizerTasks from './components/prioritizerTasks';
import PrioritizerProjects from './components/prioritizerProjects';
import ContextManager from './components/ContextManager';
import AssigneeManager from './components/AssigneeManager';
import WelcomeMessage from './components/welcomeMessage';
import Calendar from './components/Calendar';
import Assignees from './components/assignees';
import Contexts from './components/contexts';
import Comparator from './components/comparator';
import OAuth2Callback from './components/subComponents/_OAuth2Callback';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createTheme, ThemeProvider } from '@mui/material';
import Home from './app/(default)/page';
import { useInitializeData } from './store/Store';


const queryClient = new QueryClient();


const App = () => {
    console.log('welcome')
    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        components: {
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                },
            },
        },
    });

    useInitializeData();


    return (

        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <div className="bg-gray-800 min-h-screen">
                    <header>
                        <SignedOut>
                                <Home/>
                        </SignedOut>
                    </header>
                    <Router>
                        <div className={styles.appContainer}>
                            <SignedIn>
                                <SideMenu />
                            </SignedIn>

                            <Routes>
                                <Route path="/oauth2callback" element={<SignedIn><OAuth2Callback /></SignedIn>} />
                                <Route path="/" element={<SignedIn><WelcomeMessage/></SignedIn>} />
                                <Route path="/quickTask" element={<SignedIn><QuickTaskForm /></SignedIn>} />
                                <Route path="/nexttaskcapture" element={<SignedIn><NextTaskCapture /></SignedIn>} />
                                <Route path="/newtaskorganizer" element={<SignedIn><NewTaskOrganizer /></SignedIn>} />
                                <Route path="/prioritizerProjects" element={<SignedIn><PrioritizerProjects /></SignedIn>} />
                                <Route path="/prioritizerTasks" element={<SignedIn><PrioritizerTasks /></SignedIn>} />
                                <Route path="/contextmanager" element={<SignedIn><ContextManager /></SignedIn>} />
                                <Route path="/assigneemanager" element={<SignedIn><AssigneeManager /></SignedIn>} />
                                <Route path="/calendar" element={<SignedIn><Calendar /></SignedIn>} />
                                <Route path="/comparator" element={<SignedIn><Comparator /></SignedIn>} />
                                <Route path="/assignees" element={<SignedIn><Assignees /></SignedIn>} />
                                <Route path="/contexts" element={<SignedIn><Contexts /></SignedIn>} />
                            </Routes>
                        </div>
                    </Router>
                    </div>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;