import React, { useState, useEffect } from 'react';
import { Dialog, Paper, DialogTitle, DialogContent, TextField, Checkbox, DialogActions, ToggleButton, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DatePicker from './_DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { FormControlLabel } from '@mui/material';
import EventsCalendar from './_CalendarEventsCalendar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useApiEvents } from './_fetchEvents';
import useStore from '../../store/Store';


const CreateEventDialog = ({
    scheduleItDialogOpen,           // received boolean to open the dialog
    setScheduleItDialogOpen,        // sent close info when click on save or cancel
    taskName,                       // received task name
    formattedStartDate,             // received start date
    formattedEndDate,               // received end date
    formattedDeadline,              // received deadline
    user,                           // received user
    taskToProcess,                  // received task to process array
    startTime, 
    endTime,
    allDay,
    eventID,
}) => {


    const [eventName, setEventName] = useState(taskName);
    const [eventStartDate, setEventStartDate] = useState(formattedDeadline !== null ? formattedDeadline : formattedStartDate);
    const [eventEndDate, setEventEndDate] = useState(formattedDeadline !== null ? formattedDeadline : formattedEndDate);                                                                 // Event attributes declaration    


    const [scheduleAllDayEvent, setScheduleAllDayEvent] = useState(allDay);
    useEffect(() => {
        setScheduleAllDayEvent(allDay);
    }, [allDay]);

    const [eventScheduleStartTime, setEventScheduleStartTime] = useState(startTime);
    const [eventScheduleEndTime, setEventScheduleEndTime] = useState(endTime);
    // const { eventsDataset: initialEventsDataset, isLoadingEventsData } = FetchEventsData(); // Fetch the events data from the database
    const eventsDataset = useStore(state => state.events);
    // const [eventsDataset, setEventsDataset] = useState([]);                                 // Set the events data to the state
    const [calendarDialogOpen, setCalendarDialogOpen] = useState(false);                    // Set the calendar dialog to the state
    const [snackBarSuccessOpen, setSnackBarSuccessOpen] = useState(false);                  // success and error snackbar states
    const [snackBarErrorOpen, setSnackBarErrorOpen] = useState(false);
    const [snackBarErrorMessage, setSnackBarErrorMessage] = useState('');                   // error message state
    const [defaultEventDuration, setDefaultEventDuration] = useState(0);                    // default event duration state
    const { createEvent, updateEventAPI } = useApiEvents()                                                  // API call to create an event
    const { updateEvent, addEvent } = useStore() 


    useEffect(() => {                                                    
        setEventScheduleStartTime(startTime);
        setEventScheduleEndTime(endTime);
    }, [startTime, endTime]);

    useEffect(() => {                                                      
        if (taskToProcess)
            setDefaultEventDuration(taskToProcess.effort || 30);
        if (!taskToProcess) {
            setDefaultEventDuration(30);
        }
    }, [taskToProcess]);

    useEffect(() => {                                                       // Set the initial event name once task name is fetched
            setEventName(taskName);
    }, [taskName]);

    // useEffect(() => {                                                       // Set the initial events data once fetched to populate the calendar
    //     if (!isLoadingEventsData) {
    //         setEventsDataset(initialEventsDataset);
    //     }
    // }, [isLoadingEventsData, initialEventsDataset]);

    useEffect(() => {                                         
        if (formattedDeadline !== null) {
            setEventStartDate(formattedDeadline);
            setEventEndDate(formattedDeadline);
        } else {
            setEventStartDate(formattedStartDate);
            setEventEndDate(formattedEndDate);
        }
    }, [formattedDeadline, formattedStartDate, formattedEndDate]);




    const handleSaveEvent = async () => {
        let eventStartDateTime, eventEndDateTime;
        let errorMessage = "";


        if (!eventStartDate || !eventEndDate) {                             //error handling for missing start and end date
            errorMessage = "An event must have a start and end date";
            setSnackBarErrorOpen(true);
            setSnackBarErrorMessage(errorMessage);
            return;
        }

        if (scheduleAllDayEvent) {                                          // set the event start and end date based on the all day event
            eventStartDateTime = dayjs(eventStartDate).startOf('day');
            eventEndDateTime = dayjs(eventEndDate).startOf('day');
        } else {
            if (!eventScheduleStartTime || !eventScheduleEndTime) {         //error handling for missing start and end time
                errorMessage = "An event must have a start and end time";
                setSnackBarErrorOpen(true);
                setSnackBarErrorMessage(errorMessage);
                return;
            }
            eventStartDateTime = dayjs(eventStartDate).hour(dayjs(eventScheduleStartTime).hour()).minute(dayjs(eventScheduleStartTime).minute());
            eventEndDateTime = dayjs(eventEndDate).hour(dayjs(eventScheduleEndTime).hour()).minute(dayjs(eventScheduleEndTime).minute());
        }

        if (eventStartDateTime.isAfter(eventEndDateTime)) {                 //error handling for end time before start time
            errorMessage = "End time cannot be before start time";
            setSnackBarErrorOpen(true);
            setSnackBarErrorMessage(errorMessage);
        } else if (eventID == null) {
            const newEventSentToApi = {                                              // create the event object to send to the API
                event_title: eventName,
                event_start: eventStartDateTime.toISOString(),
                event_end: eventEndDateTime.toISOString(),
                event_allDay: scheduleAllDayEvent,
                user: user.id,
                event_taskId: taskToProcess ? taskToProcess.id : null
            };                     // send the event to the API
            try {
                const response = await createEvent(newEventSentToApi);
                if (response && response.id) {
                    const newEvent = {
                        ...response,
                        id: response.id,
                        name: response.name
                    };
                    
    
                    addEvent(newEvent);
    
                } else {
                    setSnackBarErrorOpen(true);
                    setSnackBarErrorMessage("Failed to create task: No ID returned from the API");
                }
    
            } catch (error) {
                setSnackBarErrorOpen(true);
                setSnackBarErrorMessage("Failed to create task: " + error);
            }
    
            setEventName('');                                               // reset the event name
            setSnackBarSuccessOpen(true)
            setScheduleItDialogOpen(false);
        } else {
            const integerEventId = parseInt(eventID, 10)
            const originalEvent = useStore.getState().events.find(event => event.id === integerEventId);
            const updatedEvent = {
                id: integerEventId,
                event_title: eventName,
                event_start:  eventStartDateTime.toISOString(),
                event_end: eventEndDateTime.toISOString(),
                event_allDay: scheduleAllDayEvent,
                user: user.id,
                event_taskId: originalEvent.event_taskId,
                complete: originalEvent.complete,
            };
            updateEvent({ ...originalEvent, ...updatedEvent });
            try {
                await updateEventAPI({ eventId: eventID, updatedEvent: updatedEvent });
            } catch (error) {
                setSnackBarErrorOpen(true);
                setSnackBarErrorMessage("Failed to update task: " + error);
                updateEvent(originalEvent);  // Revert to original task
    
                // Notify the user of the failure
            }
        }
        setScheduleItDialogOpen(false);
    };


    useEffect(() => {
        if (eventScheduleStartTime && !eventScheduleEndTime) {
            const startTime = dayjs(eventScheduleStartTime);
            const endTime = startTime.add(defaultEventDuration, 'minute');
            setEventScheduleEndTime(endTime);
        }
    }, [eventScheduleStartTime, eventScheduleEndTime, defaultEventDuration]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            {/* dialog box to schedule a new event */}
            <Dialog open={scheduleItDialogOpen} className="mx-auto md:w-1/2">
                <DialogTitle className="text-center text-lg md:text-xl">
                    {eventID === null ? 'Schedule a new event' : 'Edit Event'}
                </DialogTitle>
                <DialogContent className="p-4 md:p-6">
                    <div className="mb-4 mt-4">
                        <TextField
                            label="Name"
                            value={eventName}
                            onChange={e => {
                                setEventName(e.target.value);
                            }}
                            fullWidth
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <DatePicker
                            label="Start Date"
                            value={eventStartDate}
                            onChange={(selectedDate) => {
                                setEventStartDate(selectedDate.target.value);
                            }}
                            fullWidth
                        />
                        <DatePicker
                            label="End Date"
                            value={eventEndDate}
                            onChange={(selectedDate) => {
                                setEventEndDate(selectedDate.target.value);
                            }}
                            fullWidth
                        />
                        <TimePicker
                            label="Start Time"
                            value={eventScheduleStartTime}
                            onChange={(startTime) => setEventScheduleStartTime(startTime)}
                            disabled={!!scheduleAllDayEvent}
                        />
                        <TimePicker
                            label="End Time"
                            value={eventScheduleEndTime}
                            onChange={(endTime) => setEventScheduleEndTime(endTime)}
                            disabled={!!scheduleAllDayEvent}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!scheduleAllDayEvent}
                                    onChange={() => setScheduleAllDayEvent(!scheduleAllDayEvent)}
                                    className="col-span-full"
                                />
                            }
                            label="All Day Event"
                        />
                    </div>
                </DialogContent>
                <DialogActions className="p-4 md:p-6 justify-between">
                    <ToggleButton
                        value="seeCalendar"
                        style={{ backgroundColor: '#00CA72', padding: '5px', borderRadius: '6px' }}
                        onClick={() => setCalendarDialogOpen(true)}
                    >
                        See Calendar
                    </ToggleButton>
                    <div>
                        <Button onClick={() => setScheduleItDialogOpen(false)} className="mr-4">Cancel</Button>
                        <Button onClick={handleSaveEvent}>Save</Button>
                    </div>
                </DialogActions>
            </Dialog>

            {/* full screen calendar as dialog */}
            <Dialog
                open={calendarDialogOpen}
                onClose={() => setCalendarDialogOpen(false)}
                PaperComponent={Paper} // Use the Paper component
                PaperProps={{ style: { width: '90vw', height: '90vh', overflow: 'auto', maxWidth: 'none', backgroundColor: 'rgba(17, 24, 39)', backgroundImage: 'none' } }}
            >
                <EventsCalendar
                    events={eventsDataset}
                    // setEventsDataset={setEventsDataset}
                    openAsFullScreen={false}
                    setTasksDataset={() => {}} // Pass a dummy function
                />
            </Dialog>

            <Snackbar open={snackBarSuccessOpen} autoHideDuration={3000} onClose={() => setSnackBarSuccessOpen(false)}>
                <Alert onClose={() => setSnackBarSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
                    'Event has been scheduled successfully!'
                </Alert>
            </Snackbar>
            <Snackbar open={snackBarErrorOpen} autoHideDuration={3000} onClose={() => setSnackBarErrorOpen(false)}>
                <Alert onClose={() => setSnackBarErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {snackBarErrorMessage}
                </Alert>
            </Snackbar>

        </LocalizationProvider>
    );
};

export default CreateEventDialog;