import axios from 'axios';
import { useAuth } from "@clerk/clerk-react";

export const useApiEvents = () => {

    const { getToken } = useAuth();

    const apiCall = async (url, method, body = null) => {
        const token = await getToken()
        const options = {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    };

    const fetchEvents = async () => {
        return await apiCall('/get_calendars', 'GET');
    };

    const updateEventAPI = async (eventId) => {
        return await apiCall(`/get_calendar/${eventId.eventId}/`, 'PUT', eventId.updatedEvent);
    };

    const createEvent = async (event) => {
        return await apiCall('/get_calendars/', 'POST', event);
    };

    const deleteEventsAPI = async (eventIds) => {
        const token = await getToken()
        const responses = await Promise.all(eventIds.map(eventId =>
            axios.delete(`${process.env.REACT_APP_API_URL}/get_calendar/${eventId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
        ));

        return responses.map(response => response.data);
    };
    return { fetchEvents, createEvent, updateEventAPI, deleteEventsAPI };
}