import axios from 'axios';
import { useAuth } from "@clerk/clerk-react";

export const useApiTasks = () => {
    const { getToken } = useAuth();
    const apiCall = async (url, method, body = null) => {
        const token = await getToken()
        const options = {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    };

    const fetchTasks = async () => {
        return await apiCall('/get_tasks', 'GET');
    };

    const updateTaskAPI = async (taskId) => {
        return await apiCall(`/NtoTask/${taskId.taskId}/`, 'PUT', taskId.updatedTask);
    };

    const createTask = async (task) => {
        return await apiCall('/NtoTask/', 'POST', task);
    };

    const deleteTasksAPI = async (taskIds) => {
        const token = await getToken()
        const responses = await Promise.all(taskIds.map(taskId =>
            axios.delete(`${process.env.REACT_APP_API_URL}/NtoTask/${taskId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
        ));

        return responses.map(response => response.data);
    };
return { fetchTasks, updateTaskAPI, deleteTasksAPI, createTask };
}


