import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { TextField, Button, CircularProgress } from "@mui/material";
import { Transition } from "@headlessui/react";
import { useAuth, useUser } from "@clerk/clerk-react";
import useStore from '../store/Store'



const QuickTaskForm = () => {
    

    const { addTask } = useStore() 
    const { getToken } = useAuth();                     // get the token from the clerk user
    const { user } = useUser();                         // get clerk user information
    const [task, setTask] = useState("");               // task to be sent to the backend
    const [isLoading, setIsLoading] = useState(false);  // handle loading state for the spinner

    // dialog box variables
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [severity, setSeverity] = useState("success");


    const handleSubmit = async (event) => {
        const token = await getToken();                 // fetch clerk token
        event.preventDefault();
        setIsLoading(true);                             // loading spinner

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/quickTask/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ name: task, user: user.id, new_task: true }),
                }
            );
            if (response.ok) {
                const responseData = await response.json();  // Convert the response body to JSON
                addTask(responseData);
                setDialogMessage("Submitted successfully");
                setSeverity("success");
                setTask("");
            } else {
                setDialogMessage("There was an error submitting the task");
                setSeverity("error");
            }
        } catch (error) {
            setDialogMessage("Cannot reach the server");
            setSeverity("error");
        }

        setOpenSnackbar(true);
        setIsLoading(false);     };

    return (
        <>
        <div className="flex flex-col bg-gray-900 text-white h-auto w-[83vw] max-w-[600px] p-6 rounded-lg">
            <main> 
                <div>
                    <div className="flex-grow flex flex-col justify-center sm:w-auto w-full">
                        <div className="flex items-center justify-center mb-8">
                            <h2 className="text-2xl text-white">Clear your mind</h2>
                        </div>
                        <form
                            onSubmit={handleSubmit}
                            className="w-full p-2 rounded-md bg-input text-white"
                        >
                            <div className="flex justify-center">
                                <TextField
                                    value={task}
                                    onChange={(e) => setTask(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }
                                    }}
                                    onClick={(e) => e.target.select()}
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    placeholder="Write here your task, thoughts or whatever is in your head. We'll figure it out later together."
                                    className="w-full max-w-4xl p-2 text-white bg-primary border-none"
                                />
                            </div>
                            <div className="flex justify-center flex-wrap mt-4 mb-4 relative h-16">
                                <Transition
                                    show={task !== ""}
                                    enter="transition-opacity duration-1000"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    className="absolute"
                                >
                                    <Tooltip title="Submit your new thought or just press enter">
                                        <span>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="block w-full p-2 mt-5 text-primary bg-highlight border-none cursor-pointer"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <CircularProgress size={24} /> : "Submit"}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Transition>
                                <Transition
                                    show={task === "" }
                                    enter="transition-opacity duration-1000"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-00"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    className="absolute"
                                >
                                    <div className="flex flex-col items-center">
                                        <p className="self-center m-2 text-2xl text-white">or</p>
                                        <Link to="/newtaskorganizer">
                                            <Tooltip title="Your mind is clear? Proceed to the new task Organizer wizard">
                                                <span>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="mt-2"
                                                    >
                                                        Organize
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Link>
                                    </div>
                                </Transition>
                            </div>
                        </form>
                    </div>
                </div>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity={severity}
                        sx={{ width: "100%" }}
                    >
                        {dialogMessage}
                    </Alert>
                </Snackbar>
            </main>
        </div>
        </>
    );
};

export default QuickTaskForm;
