import React from "react";
import BaseLayout from './baselayout';
import { useUser, SignedIn, SignedOut, SignInButton } from '@clerk/clerk-react';

function WelcomeMessage() {
    const { user } = useUser();



    return (
        <BaseLayout>
            <div className="flex flex-col items-center justify-center h-[95%] bg-gray-800 text-white m-4 px-4 sm:px-0">
                {user && (
                    <SignedIn>
                        <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-8">
                            <span className="text-white">Welcome, </span> 
                            <span className="text-yellow-300">{user.firstName}</span>
                        </h1>
                        <h2 className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-center">Start to organize your mind and your day from the top left side menu</h2>
                    </SignedIn>
                )}
                <SignedOut>
                        <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-8">
                            <span className="text-white">Welcome on, </span> 
                            <span className="text-yellow-300">DONE </span>
                            <span className="text-white">The App! </span>
                        </h1>
                        <h2 className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-center">Log in or register here</h2>
                        <SignInButton />
                </SignedOut>
            </div>
        </BaseLayout>
    );
};

export default WelcomeMessage;