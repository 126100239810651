import axios from 'axios';
import { useAuth } from "@clerk/clerk-react";


export const useApiContexts = () => {

    const { getToken } = useAuth();

    const apiCall = async (url, method, body = null) => {
        const token = await getToken()
        const options = {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    };

    const fetchContexts = async () => {
        return await apiCall('/get_contexts', 'GET');
    };

    const createContext = async (context) => {
        return await apiCall('/get_contexts/', 'POST', context);
    };

    const updateContextAPI = async (contextId) => {
        return await apiCall(`/get_contexts/${contextId.contextId}/`, 'PUT', contextId.updatedContext);
    };

    const deleteContextAPI = async (contextId) => {
        const token = await getToken()
        const responses = await Promise.all(contextId.map(contextIdSingle =>
            axios.delete(`${process.env.REACT_APP_API_URL}/get_contexts/${contextIdSingle}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
        ));

        return responses.map(response => response.data);
    };
    return { fetchContexts, createContext, updateContextAPI, deleteContextAPI};
}