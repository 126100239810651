import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { TextField, Button, CircularProgress, MenuItem } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useApiProjects } from './_fetchProjects';
import PrioritySelect from './_PrioritySelect';
import StatusSelect from './_StatusSelect';
import { SelectedRowsContext } from './_prioritizerSelectedRowsContext';
import { Dropdown } from '@mui/base/Dropdown';
import Menu from '@mui/material/Menu';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';

const NextTaskCaptureHeader = () => {
    const largeScreen = useMediaQuery('(min-width:640px)');
    const { fetchProjectsAPI, updateProjectAPI } = useApiProjects();
    const {                                                                             // fetch projects from the API
        data: fetchedAPIProjectsData, 
        isLoading: isLoadingProjectsFromApi, 
        error: errorLoadingProjectsFromApi, 
        refetch
    } = useQuery('fetchedProjectsData', fetchProjectsAPI);

    const [indexOfProjectToBeReviewed, setIndexOfProjectToBeReviewed] = useState(0);    // set the index of the project to be reviewed
    const [projectToBeReviewed, setProjectToBeReviewed] = useState(                     // set the project to be reviewed
        fetchedAPIProjectsData ? 
        fetchedAPIProjectsData[indexOfProjectToBeReviewed] : null);
    const [projectDropDown, setProjectDropDown] = useState(null);                       // set the project dropdown menu

    const handleProjectDropDownClick = (project) => {                                   // project dropdown behavior
        return () => {
            if (project.id === null) {                                                  // if the project is null, set the project to be reviewed to null
                setProjectToBeReviewed(null);
            } else {                                                                    // pass the project name to match the project to be reviewed
                findProjectToBeReviewed(project.project_name);
            }
            setProjectDropDown(null);                                                   // close the dropdown menu
        };
    };

    useEffect(() => {                                                                   // update project info to be displayed
        if (fetchedAPIProjectsData) {
            setProjectToBeReviewed(fetchedAPIProjectsData[indexOfProjectToBeReviewed]);
        }
    }, [fetchedAPIProjectsData, indexOfProjectToBeReviewed]);

    const [, setSelectedRowsContext] = React.useContext(SelectedRowsContext);           // pass the filter value to the task table
    
    useEffect(() => {                                                                   // set the selected rows context
        if (projectToBeReviewed) {
            setSelectedRowsContext([projectToBeReviewed.id]);                           
        }
        else {
            setSelectedRowsContext([null]);
        }
    }, [projectToBeReviewed, setProjectToBeReviewed, setSelectedRowsContext]);

    const handleNext = () => {
        setIndexOfProjectToBeReviewed((prevIndex) => prevIndex + 1);
        setProjectToBeReviewed(fetchedAPIProjectsData[indexOfProjectToBeReviewed]);
    };
    
    const handlePrevious = () => {
        setIndexOfProjectToBeReviewed((prevIndex) => prevIndex - 1);
        setProjectToBeReviewed(fetchedAPIProjectsData[indexOfProjectToBeReviewed]);
    };

    const findProjectToBeReviewed = (projectName) => {                                  // match project name selected from dropdown with local project data
        const selectedProjectIndex = fetchedAPIProjectsData.findIndex(
            (project) => project.project_name === projectName
        );
        setIndexOfProjectToBeReviewed(selectedProjectIndex);
        setProjectToBeReviewed(fetchedAPIProjectsData[selectedProjectIndex]);
    };

    const [projectNameInputValue, setProjectNameInputValue] = useState('');             // duplicate the name of the project to be reviewed into the editable field

    useEffect(() => {                                                                   // duplicate the name of the project to be reviewed into the editable field
        if (projectToBeReviewed) {
            setProjectNameInputValue(projectToBeReviewed.project_name);
        }
    }, [projectToBeReviewed]);    

    useEffect(() => {
        const handleUpdate = async () => {
            try {
                await updateProjectAPI({ projectId: projectToBeReviewed.id, updatedProject: projectToBeReviewed });
                refetch();
            } catch (error) {
            }
        };
        handleUpdate();
    }, [projectToBeReviewed, refetch]);

    if (isLoadingProjectsFromApi) return 'Loading...';
    if (errorLoadingProjectsFromApi) return 'An error has occurred: ' + errorLoadingProjectsFromApi.message;
    if (!fetchedAPIProjectsData) return 'Loading...';

    const totalProjects = fetchedAPIProjectsData.length;

    if (!fetchedAPIProjectsData) {
        return <CircularProgress />;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex flex-col space-y-4">
                <div>
                    {projectToBeReviewed && (
                        <div className="flex items-center space-x-4 mb-0 sm:mb-12" style={{ alignItems: 'center' }}>
                            <p className="mb-4">Project <span className="font-bold text-yellow-300 text-4xl ">{indexOfProjectToBeReviewed + 1}</span> of <span className="font-bold text-blue-500 text-4xl">{totalProjects}</span></p>
                        </div>
                    )}
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                        <TextField
                            label={projectToBeReviewed ? "Project Name" : "Orphan tasks"} 
                            value={projectToBeReviewed ? projectNameInputValue : ''}
                            onChange={(e) => setProjectNameInputValue(e.target.value)}
                            onBlur={() => projectToBeReviewed && setProjectToBeReviewed({ ...projectToBeReviewed, project_name: projectNameInputValue })}
                            disabled={!projectToBeReviewed}
                        />
                        {projectToBeReviewed && (
                            <>
                            {largeScreen ? (
                            <>
                                <DatePicker 
                                label="Deadline"
                                value={new Date(projectToBeReviewed.project_deadline)} 
                                onChange={(date) => setProjectToBeReviewed({ ...projectToBeReviewed, project_deadline: date.toISOString().split('T')[0] })}
                                />
                                <PrioritySelect 
                                value={projectToBeReviewed.project_priority} 
                                onChange={(e) => {
                                    setProjectToBeReviewed({ ...projectToBeReviewed, project_priority: e.target.value });
                                }}
                                />
                                <StatusSelect 
                                value={projectToBeReviewed.project_status} 
                                onChange={(e) => setProjectToBeReviewed({ ...projectToBeReviewed, project_status: e.target.value })}
                                />
                            </>
                            ) : (
                            <Accordion sx={{ backgroundColor: '#1A202C' }}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <div>Project Details</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
                                    <DatePicker 
                                    label="Deadline"
                                    value={new Date(projectToBeReviewed.project_deadline)} 
                                    onChange={(date) => setProjectToBeReviewed({ ...projectToBeReviewed, project_deadline: date.toISOString().split('T')[0] })}
                                    />
                                    <PrioritySelect 
                                    value={projectToBeReviewed.project_priority} 
                                    onChange={(e) => {
                                        setProjectToBeReviewed({ ...projectToBeReviewed, project_priority: e.target.value });
                                    }}
                                    />
                                    <StatusSelect 
                                    value={projectToBeReviewed.project_status} 
                                    onChange={(e) => setProjectToBeReviewed({ ...projectToBeReviewed, project_status: e.target.value })}
                                    />
                                </div>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        </>
                        )}
                    </div>
                </div>
                <div className="flex">
                <div className="flex items-center space-x-4 mb-12" style={{ alignItems: 'center' }}>
                    {projectToBeReviewed && (
                        <>
                            {indexOfProjectToBeReviewed > 0 && <Button onClick={handlePrevious}>Previous</Button>}
                            {indexOfProjectToBeReviewed < totalProjects - 1 && <Button onClick={handleNext}>Next</Button>}
                            <p>or</p>
                        </>
                    )}
                    <Dropdown>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setProjectDropDown(event.currentTarget)}>
                                Select a specific project
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={projectDropDown}
                                keepMounted
                                open={Boolean(projectDropDown)}
                                onClose={() => setProjectDropDown(null)}
                            >
                                {fetchedAPIProjectsData.map((project) => (
                                    <MenuItem key={project.id} onClick={handleProjectDropDownClick(project)}>
                                        {project.project_name}
                                    </MenuItem>
                                ))}
                                <MenuItem 
                                    key="orphan" 
                                    onClick={handleProjectDropDownClick({ id: null })}
                                    style={{ color: 'red' }} // Change 'red' to your preferred color
                                >
                                    Orphan tasks
                                </MenuItem>
                            </Menu>
                        </Dropdown>
                        </div>
                </div>
            </div>
        </LocalizationProvider>
    );
};

export default NextTaskCaptureHeader;