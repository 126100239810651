import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useApiAssignees } from './subComponents/_fetchAssignees';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from "@clerk/clerk-react";
import { TextField, Fab, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import useStore from '../store/Store'
import Snackbar from "@mui/material/Snackbar";

const AssigneeManager = () => {
    const { createAssignee, updateAssigneeAPI, deleteAssigneeAPI } = useApiAssignees();     //CRUD API
    const { addAssignee, updateAssignee, removeAssignee, setAssignees } = useStore()                      // CRUD STORE
    const [SnackBarErrorOpen, setSnackBarErrorOpen] = useState(false);                // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');  // snackbar for dialog message display
    const { user } = useUser();                         // get clerk user information
    const [selectedRows, setSelectedRows] = useState([]);           // rows selected in the table
    const [assigneesData, updateAssigneesData] = useState([]);        // assignee data
    const [name, setName] = useState('');                           // dialog box form fields
    const [description, setDescription] = useState('');
    const storeAssigneesData = useStore(state => state.assignees);
    const isLoading = useStore(state => state.isLoading);           // get the isLoadingFromApi from the store
    const errorLoading = useStore(state => state.error);             // get the errorLoadingFromApi from the store  


    useEffect(() => {                                               // loading assignee data on local variable
        if (storeAssigneesData) {
            updateAssigneesData(storeAssigneesData);
        }
    }, [storeAssigneesData]);

    const columns = [                                               // columns for the assignee data table
        {
            field: 'name',
            headerName: 'Assignee Name',
            width: 250,
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value.toString() : ''} enterDelay={500}>
                    <div>
                        <TextField
                            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                            defaultValue={params.value}
                            onKeyDown={(event) => {
                                if (event.key === ' ') {
                                    event.stopPropagation();
                                }
                                if (event.key === 'a' && event.ctrlKey) {
                                    event.stopPropagation();
                                }
                            }}
                            onBlur={(event) => updateAssigneeAttributes(params, 'name', event.target.value)}
                        />
                    </div>
                </Tooltip>
            ),
        },
        { 
            field: 'description', 
            headerName: 'Description', 
            width: 300, 
            renderCell: (params) => (
                <Tooltip title={params.value ? params.value.toString() : ''} enterDelay={500}>
                    <div>
                        <TextField
                            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                            defaultValue={params.value}
                            onKeyDown={(event) => {
                                if (event.key === ' ') {
                                    event.stopPropagation();
                                }
                                if (event.key === 'a' && event.ctrlKey) {
                                    event.stopPropagation();
                                }
                            }}
                            onBlur={(event) => updateAssigneeAttributes(params, 'description', event.target.value)}
                        />
                    </div>
                </Tooltip>
            ),
        },
    ];

    const updateAssigneeAttributes = async (params, field, value) => {               // updateAssignee function to update the assignee data on table edit
        const updatedAssignee = assigneesData.find((assignee) => assignee.id === params.id);
        const originalAssignee = assigneesData.find((assignee) => assignee.id === params.id);
        if (updatedAssignee) {
            updatedAssignee[field] = value;
        }
        updateAssignee({ ...originalAssignee, ...updatedAssignee });
        try {
            await updateAssigneeAPI({ assigneeId: updatedAssignee.id, updatedAssignee: updatedAssignee });
        } catch (error) {
            setSnackBarErrorOpen(true);
            setDialogMessageSnackBar("Failed to update assignee: " + error);
            updateAssignee(originalAssignee);  // Revert to original assignee
        }
    };


    const handleDelete = async () => {                              // Function to handle the delete button click
        const originalAssignees = useStore.getState().assignees;
        removeAssignee(selectedRows);
        try {
            await deleteAssigneeAPI(selectedRows);
        } catch (error) {
            setSnackBarErrorOpen(true);
            setDialogMessageSnackBar("Failed to remove assignee: " + error);
            setAssignees(originalAssignees);  // Restore the original Assignees
        }
        setSelectedRows([]);
        setOpenDelete(false);

    };

    const handleSubmit = async () => {
        const newAssignee = {
            name: name,
            description: description,
            user: user.id,
        };
        try {
            const response = await createAssignee(newAssignee);
            if (response && response.id) {
                const newAssignee = {
                    ...response,
                    id: response.id,
                    name: response.name
                };
                
                addAssignee(newAssignee);

            } else {
                setSnackBarErrorOpen(true);
                setDialogMessageSnackBar("Failed to create assignee: No ID returned from the API");
            }

        } catch (error) {
            setSnackBarErrorOpen(true);
            setDialogMessageSnackBar("Failed to create assignee: " + error);
        }

        setOpenAdd(false);
        setName(''); // reset name
    };


    const [openAdd, setOpenAdd] = useState(false); // useState hook to store and update the open state of the add assignee dialog
    const [openDelete, setOpenDelete] = useState(false); // useState hook to store and update the open state of the delete confirmation dialog

    const handleAdd = () => {
        setOpenAdd(true);
    };

    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleClose = () => {
        setOpenAdd(false);
        setOpenDelete(false);
    };

    return (
            <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                {isLoading ? (
                    <CircularProgress />
                ) : errorLoading ? (
                    <Alert severity="error">Error loading assignees</Alert>
                ) : assigneesData !== undefined ? (
                    <DataGrid 
                        rows={assigneesData} 
                        columns={columns} 
                        disableRowSelectionOnClick
                        checkboxSelection
                        pageSize={assigneesData.length}
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedRows(newSelection);
                        }}
                    />
                ) : null}
                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Fab color="primary" aria-label="add" onClick={handleAdd} style={{ marginRight: 10 }}>
                        <AddIcon />
                    </Fab>
                    {selectedRows.length > 0 && (
                        <Fab color="secondary" aria-label="delete" onClick={handleClickOpenDelete}>
                            <DeleteIcon />
                        </Fab>
                    )}
                    <Dialog
                        open={openAdd}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Add Assignee"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please enter the name and description for the new assignee.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Name"
                                type="text"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                label="Description"
                                type="text"
                                fullWidth
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleSubmit} autoFocus>
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openDelete}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete the selected assignee(s)?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleDelete} autoFocus>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Snackbar
                    open={SnackBarErrorOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackBarErrorOpen(false)}
                >
                    <Alert
                        onClose={() => setSnackBarErrorOpen(false)}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        {dialogMessageSnackBar}
                    </Alert>
                </Snackbar>
            </div>
    );
}

export default AssigneeManager;