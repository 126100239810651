import Hero from '../../components/landingComponents/hero'
import Features from '../../components/landingComponents/features'
import Newsletter from '../../components/landingComponents/newsletter'
import Zigzag from '../../components/landingComponents/zigzag'
import Testimonials from '../../components/landingComponents/testimonials'
import DefaultLayout from './layout'

export const metadata = {
  title: 'priorizeMe - Home',
  description: 'priorizeMe! is a powerful task management tool that helps you declutter your mind, prioritize tasks, and achieve your goals efficiently. Capture thoughts, organize projects, schedule actions, and stay focused on what matters most.',
}

export default function Home() {
  return (
    <DefaultLayout>
      <Hero />
      <div id="features">
        <Features />
      </div>
      <Zigzag />
      <Testimonials />
      <Newsletter />
    </DefaultLayout>
  )
}