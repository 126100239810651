import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Button } from '@mui/material';
import BaseLayout from './baselayout';
import { useApiProjects } from './subComponents/_fetchProjects';
import ScoreTable from './subComponents/_comparatorScoreTable';

const Comparator = () => {
    const headerContent = "Project Comparator";                     // header content

    const { fetchProjectsAPI } = useApiProjects();                   // fetch projects data from the API

    const {                                                         // fetch projects data from the API
        data: fetchedProjectsData,
        // isLoading: isLoadingProjects,
        // error: errorLoadingProjects
    } = useQuery('fetchedProjectsData', fetchProjectsAPI);

    const [localProjectsData, setLocalProjectsData] = useState([]); // declare projects data local array
    const [comparisonPlan, setComparisonPlan] = useState([]);       // declare comparison plan array

    useEffect(() => {                                               // load a filtered project list to local array and define a comparison plan
        if (fetchedProjectsData) {
            let filteredProjects = fetchedProjectsData.filter(project =>            // filter out completed and cancelled projects 
                project.project_status !== 'Co' && project.project_status !== 'Cn'
            );
            let projectsToBeCompared = filteredProjects.map(project => ({           // add a project_score field to each project
                ...project,
                project_score: 0
            }));
            projectsToBeCompared = [].concat(...projectsToBeCompared);              // flatten the array
            let comparisonPlan = [];                                               // declare a comparison plan array
            for (let i = 0; i < projectsToBeCompared.length; i++) {                 // create a comparison plan
                for (let j = i + 1; j < projectsToBeCompared.length; j++) {
                    comparisonPlan.push([
                        { id: projectsToBeCompared[i].id, name: projectsToBeCompared[i].project_name },
                        { id: projectsToBeCompared[j].id, name: projectsToBeCompared[j].project_name }]);
                }
            }
            setComparisonPlan(comparisonPlan);
            setLocalProjectsData(projectsToBeCompared);
        }
    }, [fetchedProjectsData]);


    const [currentComparison, setCurrentComparison] = useState(0);  // current comparison round
    const [currentPair, setCurrentPair] = useState(                 // current pair of projects to be compared on the current round
        comparisonPlan[currentComparison]);

    useEffect(() => {                                               // update the current pair of projects to be compared
        setCurrentPair(comparisonPlan[currentComparison]);
    }, [comparisonPlan, currentComparison]);

    // const handleDragStart = (event, verticalPositionPickedFrom) => {    //set the dragged item original position
    //     event.dataTransfer.setData("draggedItemIndex", verticalPositionPickedFrom);      // set the dragged item index
    // };

    // const handleDrop = (event, droppedToId) => {                    // reorder the current pair of projects after dropping
    //     const pickedFromID = event.dataTransfer.getData("draggedItemIndex");// get the dragged from item position
    //     const copyOfCurrentPair = Array.from(currentPair);                  // create a copy of the current pair
    //     const [reorderedItem] = copyOfCurrentPair.splice(pickedFromID, 1);  // remove the dragged item from the original position
    //     copyOfCurrentPair.splice(droppedToId, 0, reorderedItem);            // insert the dragged item to the new position
    //     setCurrentPair(copyOfCurrentPair);
    // };

    const handleNext = () => {                                      // increment the current comparison round and update the project scores
        setCurrentComparison(currentComparison + 1);                // Increment the current comparison index  
        const updatedProjects = localProjectsData.map(project => {  // Increment the project_score of the project that remains on top
            if (project.id === currentPair[0].id) {
                return { ...project, project_score: project.project_score + 1 };
            }
            return project;
        });
        setLocalProjectsData(updatedProjects);                      // Update the local projects dataset
    };

    const handleSwap = () => { // function to swap the current pair of projects
        const copyOfCurrentPair = Array.from(currentPair);
        setCurrentPair(copyOfCurrentPair.reverse());
    };

    const isComparisonComplete = currentComparison + 1 > localProjectsData.length * (localProjectsData.length - 1) / 2; // check if the comparison is complete

if (localProjectsData && localProjectsData.length < 3) { 
    return (
        <BaseLayout headerContent={headerContent}>
            <div className="flex flex-col items-center justify-center h-[95%] bg-gray-800 text-white m-4 px-4 sm:px-0">
                <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-8">
                    <span className="text-yellow-300">Create more projects.</span>
                </h1>
                <h2 className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-center">
                    You need to have at least 3 projects to use the comparator tool.
                </h2>
            </div>
        </BaseLayout>
    );
}

return (
    <BaseLayout headerContent={headerContent}>
        {isComparisonComplete ? (
            <ScoreTable data={localProjectsData} />
        ) : (
            <>
                {currentPair && (
                    <div>
                        <div className="flex justify-center space-x-4 mb-12">
                            <p><span className="font-bold text-blue-500 text-4xl">{localProjectsData.length}</span> projects to be compared.</p>
                            <p className="md:text-left text-right">Round <span className="font-bold text-yellow-300 text-4xl">{currentComparison + 1}</span> of <span className="font-bold text-blue-500 text-4xl">{localProjectsData.length * (localProjectsData.length - 1) / 2}</span></p>
                        </div>
                        <div
                            className="h-[25vh] max-w-[25rem] mx-auto p-4 rounded-lg shadow-lg mb-4 bg-gradient-to-r from-green-400 to-blue-500 flex items-center justify-center overflow-hidden"
                        >
                            <p className="text-center w-full overflow-ellipsis overflow-hidden" style={{ fontSize: 'clamp(16px, 8vw, 60px)', padding: '8px', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}>{currentPair[0].name}</p>
                        </div>
                        <div className="my-4 text-center">
                            <span>is more important than</span>
                        </div>
                        <div
                            className="h-[25vh] max-w-[25rem] mx-auto p-4 rounded-lg shadow-lg mb-4 bg-gradient-to-r from-red-500 to-yellow-400 flex items-center justify-center overflow-hidden"
                        >
                            <p className="text-center w-full overflow-ellipsis overflow-hidden" style={{ fontSize: 'clamp(16px, 8vw, 60px)', padding: '8px', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}>{currentPair[1].name}</p>
                        </div>
                    </div>

                )}
                <div className="flex justify-center">
                    <Button onClick={handleSwap}>Swap</Button>
                    <Button onClick={handleNext}>Next</Button>
                </div>
            </>
        )}
    </BaseLayout>
);
}

export default Comparator;