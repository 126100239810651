// component used in new task organizer
import React from 'react';
import { TextField } from '@mui/material';
import { commonStyles } from './_commonStyles';

const DatePicker = ({ label, value, onChange }) => (
    <TextField
        sx={{ ...commonStyles, minWidth: 150 }}
        type="date"
        label={label}
        value={value}
        onChange={onChange}
        InputLabelProps={{
            shrink: true,
        }}
    />
);

export default DatePicker;