import { Edit, FilterList, CompareArrows, CalendarToday, LocationOn, Group } from '@mui/icons-material';
import { SignInButton } from "@clerk/clerk-react";

export default function Features() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">So many often feel overwhelmed by having too many things to do.</h2>
            <p className="text-xl text-gray-400">Feeling stress, uncertainty, and the fear of not using time well.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <div className="bg-purple-600 rounded-full inline-flex items-center justify-center w-16 h-16">
                  <Edit className="text-purple-100" style={{ fontSize: 32 }} />
              </div>
              <h4 className="h4 mb-2">Thoughts capture</h4>
              <p className="text-lg text-gray-400 text-center">Swiftly offload mental burdens through writing, freeing your mind to laser-focus on the here and now.</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
              <div div className="bg-purple-600 rounded-full inline-flex items-center justify-center w-16 h-16">
                  <FilterList className="text-purple-100" style={{ fontSize: 32 }} />
              </div>
              <h4 className="h4 mb-2">Task crafting</h4>
              <p className="text-lg text-gray-400 text-center">Transmute amorphous thoughts into well-defined S.M.A.R.T. projects and actions, or decisively discard what lacks practicality.</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]">
              <div div className="bg-purple-600 rounded-full inline-flex items-center justify-center w-16 h-16">
                  <CompareArrows className="text-purple-100" style={{ fontSize: 32 }} />
              </div>
              <h4 className="h4 mb-2">Priority battles</h4>
              <p className="text-lg text-gray-400 text-center">where your projects engage in a contest to unveil a definitive ranked order, providing crystal-clear guidance on where to channel your efforts.</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]">
              <div div className="bg-purple-600 rounded-full inline-flex items-center justify-center w-16 h-16">
                  <CalendarToday className="text-purple-100" style={{ fontSize: 32 }} />
              </div>
              <h4 className="h4 mb-2">Schedule</h4>
              <p className="text-lg text-gray-400 text-center">Plan out your entire day in detail, creating a clear schedule to follow closely and stay on track.</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
              <div div className="bg-purple-600 rounded-full inline-flex items-center justify-center w-16 h-16">
                  <LocationOn className="text-purple-100" style={{ fontSize: 32 }} />
              </div>
              <h4 className="h4 mb-2">Contexts</h4>
              <p className="text-lg text-gray-400 text-center">Tailors your workflow to match your current environment, be it deep work, meetings, home, or mobile, keeping you laser-focused on the right tasks..</p>
            </div>

            {/* 6th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">
              <div div className="bg-purple-600 rounded-full inline-flex items-center justify-center w-16 h-16">
                  <Group className="text-purple-100" style={{ fontSize: 32 }} />
              </div>
              <h4 className="h4 mb-2">Assignees</h4>
              <p className="text-lg text-gray-400 text-center">Know what tasks belong to who, easily delegate, follow up, and make sure important topics are addressed when meeting with specific people.</p>
            </div>
          </div>
          <div className="w-full p-16 mx-auto sm:flex sm:justify-center">
              <div data-aos="fade-up" data-aos-delay="400">
                <SignInButton className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0">
                  Become a beta tester!
                </SignInButton>
              </div>
            </div>
        </div>

      </div>
    </section>
  )
}
