import React from 'react';
import './css/style.css'
import { BrowserRouter as Router } from 'react-router-dom';

import Header from '../components/landingComponents/ui/header'

export default function RootLayout({ children }) {
  return (
    <Router>
      <div className="font-inter antialiased bg-gray-900 text-gray-200 tracking-tight">
        <div className="flex flex-col min-h-screen overflow-hidden">
          <Header />
          {children}
          {/* <Banner /> */}
        </div>
      </div>
    </Router>
  )
}