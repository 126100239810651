import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function TaskTypeSelection({ inputType, setInputType }) {
    const isMobile = useMediaQuery('(max-width:640px)');

    const handleChange = (event, newInputType) => {
        setInputType(newInputType);
    };


    return isMobile ? (
        <ToggleButtonGroup
            color="primary"
            value={inputType}
            exclusive
            onChange={handleChange}
            aria-label="Task type"
        >
            <ToggleButton value="task">Task</ToggleButton>
            <ToggleButton value="project">Project</ToggleButton>
            <ToggleButton value="nonActionable">Non Actionable</ToggleButton>
        </ToggleButtonGroup>
    ) : (
        <RadioGroup
            className="flex flex-row my-4 items-left"
            value={inputType}
            onChange={handleChange}
        >
            <FormControlLabel
                value="task"
                control={
                    <Radio
                        color="default"
                        sx={{
                            color: "grey",
                            "&.Mui-checked": { color: "#579BFC" },
                        }}
                    />
                }
                label="A task"
                className="flex items-center"
            />
            <FormControlLabel
                value="project"
                control={
                    <Radio
                        color="default"
                        sx={{
                            color: "grey",
                            "&.Mui-checked": { color: "#579BFC" },
                        }}
                    />
                }
                label="A Project"
                className="flex items-center"
            />
            <FormControlLabel
                value="nonActionable"
                control={
                    <Radio
                        color="default"
                        sx={{
                            color: "grey",
                            "&.Mui-checked": { color: "#579BFC" },
                        }}
                    />
                }
                label="Non Actionable"
                className="flex items-center"
            />
        </RadioGroup>
    );
}