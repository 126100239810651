import { useEffect } from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import PageIllustration from '../../components/landingComponents/page-illustration'
import Footer from '../../components/landingComponents/ui/footer'
import RootLayout from '../layout'

export default function DefaultLayout({ children }) {  

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  }, [])

  return (
    <RootLayout>
      <main className="grow">
        <PageIllustration />
        {children}
      </main>
      {/* <Footer /> */}
    </RootLayout>
  )
}