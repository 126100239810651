import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import { Dialog } from '@mui/material';
import BaseLayout from './baselayout';
import ContextManager from './ContextManager';
import TasksTable from './subComponents/_calendarTasksTable';
import useStore from '../store/Store'

function Contexts() {

    const headerContent = "Contexts";
    const [contextsData, updateContextsData] = useState([]);                                  // fetch contexts data
    const [tasks, setTasks] = useState([]);                                                     // fetch tasks data
    const [taskCount, setTaskCount] = useState({});                                             // count of tasks assigned to each context
    const [showTasksTable, setShowTasksTable] = useState(false);                                // show the TasksTable component
    const [selectedTasks, setSelectedTasks] = useState([]);                                     // tasks assigned to a specific context
    const [selectedContext, setSelectedContext] = useState(null);                             // selected context
    const storeContextsData = useStore(state => state.contexts);
    const storeTasksData = useStore(state => state.tasks);

    console.log(storeTasksData)

    useEffect(() => {                                                                           // update the contextsData state once the data is fetched
        if (storeContextsData) {
            updateContextsData(storeContextsData);
        }
    }, [storeContextsData]);

    useEffect(() => {                                                                           // update the tasks state once the data is fetched
        if (storeTasksData) {
            const filteredTasks = storeTasksData.filter(task => task.status !== 'Cn' && task.status !== 'Co');
            setTasks(filteredTasks);
        }
    }, [storeTasksData]);

    useEffect(() => {
        const count = contextsData.reduce((acc, context) => {
            acc[context.name] = tasks.filter(task => task.context === context.id).length;
            return acc;
        }, {});

        // Count tasks with no context
        count['No Context'] = tasks.filter(task => task.context === null).length;
        setTaskCount(count);
    }, [contextsData, tasks, storeContextsData, storeTasksData]);

    const handleContextClick = (context) => {                                                 // filter tasks assigned to a specific context
        const contextTasks = tasks.filter(task => task.context === context.id);
        setSelectedContext(context);
        setSelectedTasks(contextTasks);
        setShowTasksTable(true);
    };

    useEffect(() => {                                                                           // update the selectedTasks once the selectedContext state is updated
        if (selectedContext) {
            const contextTasks = tasks.filter(task => task.context === selectedContext.id);
            setSelectedTasks(contextTasks);
        }
    }, [tasks, selectedContext]);

    const handleTaskUpdateFromTaskTable = (updatedTask) => {

        const taskIndex = tasks.findIndex(task => task.id === updatedTask.id);
        let newTasks;
        if (taskIndex !== -1) {                             // task exists, update it
            newTasks = [...tasks];
            newTasks[taskIndex] = updatedTask;
        } else {                                            // task doesn't exist, add it
            newTasks = [...tasks, updatedTask];
        }


        const filteredTasks = newTasks.filter(task => task.status !== 'Cn' && task.status !== 'Co');
        setTasks(filteredTasks);
    };

    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
    setOpen(true);
    };

    const handleClose = () => {
    setOpen(false);
    };
    
    return (
        <BaseLayout headerContent={headerContent}>
            <div>
                <Dialog 
                    open={open} 
                    onClose={handleClose} 
                    fullWidth 
                    maxWidth="md" 
                    PaperProps={{ style: { maxWidth: '90vw', maxHeight: '90vh', width: 'auto', height: 'auto' } }}
                    >
                    <ContextManager />
                </Dialog>
            </div>
            <div className="max-w-[1200px] mx-auto">
                <Grid container spacing={3}>
                    {[...contextsData, { name: 'No Context', id: null }]
                        .sort((a, b) => {
                            if (a.name === 'No Context') return 1;
                            if (b.name === 'No Context') return -1;
                            return taskCount[b.name] - taskCount[a.name];
                        })
                        .map(context => (
                            taskCount[context.name] > 0 && (
                                <Grid item key={context.id || 'no-context'}>
                                    <Badge badgeContent={taskCount[context.name]} color="primary">
                                        <Chip label={context.name} onClick={() => handleContextClick(context)} />
                                    </Badge>
                                </Grid>
                            )
                        ))}
                    <Grid item>
                        <Chip label="Manage Contexts" onClick={handleClickOpen} style={{backgroundColor: '#00CA72'}} />
                    </Grid>
                </Grid>
                {showTasksTable && <TasksTable tasks={selectedTasks} onTasksUpdate={handleTaskUpdateFromTaskTable} />}
            </div>
        </BaseLayout>
    );
}

export default Contexts;