import axios from 'axios';
import { useAuth } from "@clerk/clerk-react";


export const useApiAssignees = () => {

    const { getToken } = useAuth();

    const apiCall = async (url, method, body = null) => {
        const token = await getToken()
        const options = {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    };



    const fetchAssignees = async () => {
        return await apiCall('/get_assignees', 'GET');
    };

    const createAssignee = async (assignee) => {
        return await apiCall('/get_assignees/', 'POST', assignee);
    };

    const updateAssigneeAPI = async (assigneeId) => {
        console.log('assigneeId', assigneeId);
        return await apiCall(`/get_assignee/${assigneeId.assigneeId}/`, 'PUT', assigneeId.updatedAssignee);
    };

    const deleteAssigneeAPI = async (assigneeId) => {
        const token = await getToken()
        const responses = await Promise.all(assigneeId.map(assigneeIdSingle =>
            axios.delete(`${process.env.REACT_APP_API_URL}/get_assignee/${assigneeIdSingle}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
        ));

        return responses.map(response => response.data);
    };
    return { fetchAssignees, createAssignee, updateAssigneeAPI, deleteAssigneeAPI};
};