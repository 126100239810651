import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@mui/material';
import { useApiContexts } from './_fetchContexts';
import { useUser } from "@clerk/clerk-react";

import useStore from '../../store/Store'
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

const ContextDialogBox = ({ setSelectedContext }) => {
    const { createContext } = useApiContexts();         //CRUD API
    const { addContext } = useStore()                   // CRUD STORE
    const [openSnackbar, setOpenSnackbar] = useState(false);                // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');  // snackbar for dialog message display

    const [addNewContextDialogOpen, setAddNewContextDialogOpen] = useState(false);
    const [newContextNameToBeAdded, setNewContextNameToBeAdded] = useState("");
    const [newContextDescriptionToBeAdded, setNewContextDescriptionToBeAdded] = useState("");
    const { user } = useUser();                         // get clerk user information

    const handleAddContextByOpeningDialogBox = useCallback(() => {
        setAddNewContextDialogOpen(true);
    }, []);

    const handleCloseAddContextDialogBox = useCallback(() => {
        setAddNewContextDialogOpen(false);
    }, []);

    const handleNameChange = useCallback((event) => {
        setNewContextNameToBeAdded(event.target.value || "");
    }, []);

    const handleDescriptionChange = useCallback((event) => {
        setNewContextDescriptionToBeAdded(event.target.value || "");
    }, []);

    const handleSubmitNewContext = useCallback(async () => {
        const newContext = {
            name: newContextNameToBeAdded,
            description: newContextDescriptionToBeAdded,
            user: user.id,
        };
        try {
            const response = await createContext(newContext);
            if (response && response.id) {
                const newContext = {
                    ...response,
                    id: response.id,
                    name: response.name
                };
                
                addContext(newContext);

            } else {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to create context: No ID returned from the API");
            }

        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to create context: " + error);
        }

        setNewContextDescriptionToBeAdded("");
        setNewContextNameToBeAdded("");
        setAddNewContextDialogOpen(false);

    }, [newContextNameToBeAdded, newContextDescriptionToBeAdded, user.id, createContext, addContext]);

    return (
        <div>
            <Button onClick={handleAddContextByOpeningDialogBox}>+ add context</Button>
            <Dialog open={addNewContextDialogOpen} onClose={handleCloseAddContextDialogBox}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Enter the name and description for the new context.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newContextNameToBeAdded}
                        onChange={handleNameChange}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        value={newContextDescriptionToBeAdded}
                        onChange={handleDescriptionChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddContextDialogBox}>Cancel</Button>
                    <Button onClick={handleSubmitNewContext}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {dialogMessageSnackBar}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ContextDialogBox;