import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import BaseLayout from './baselayout';
import TasksPrioritizer from './subComponents/_prioritizerTasks';
import NextTaskCaptureHeader from './subComponents/_NextTaskCaptureHeader';
import { SelectedRowsContext } from './subComponents/_prioritizerSelectedRowsContext';

const Prioritizer = () => {
    const headerContent = "Next Task Capture";
    const [selectedRowsContext, setSelectedRowsContext] = useState([]);
    const [headerLoaded, setHeaderLoaded] = useState(false);
    const [isDataEmpty, setIsDataEmpty] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHeaderLoaded(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleDataLengthChange = (newLength) => {
        setIsDataEmpty(newLength === 0);
    };


    return (
        <BaseLayout headerContent={headerContent}>
            <div className="flex flex-col text-white p-6 space-y-4 w-full max-h-full overflow-hidden">
                <SelectedRowsContext.Provider value={[selectedRowsContext, setSelectedRowsContext]}>
                    {isDataEmpty ? (
                        <>
                        <div className="flex flex-col items-center justify-center h-[70vh] bg-gray-800 text-white ml-4 px-4 sm:px-0">
                            <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-8">
                                <span className="text-yellow-300">Nothing to review here.</span>
                            </h1>
                            <h2 className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-center">
                                Add new entries and come back to find out what's next on your goal.
                            </h2>
                        </div>
                        </>
                        ) : (
                            <div className="flex flex-col h-full max-h-full overflow-hidden">
                                <div>
                                    <NextTaskCaptureHeader />
                                </div>
                                {headerLoaded ? (
                                    <div className="overflow-auto flex-grow">
                                        <TasksPrioritizer onDataLengthChange={handleDataLengthChange} />
                                    </div>
                                ) : (
                                    <CircularProgress />
                                )}
                            </div>
                        )
                        }
                </SelectedRowsContext.Provider>
            </div>
        </BaseLayout>
    );
}

export default Prioritizer;