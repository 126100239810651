import axios from 'axios';
import { useAuth } from "@clerk/clerk-react";

export const useApiProjects = () => {
    const { getToken } = useAuth();
    const apiCall = async (url, method, body = null) => {
        const token = await getToken()
        const options = {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    };

    const fetchProjectsAPI = async () => {
        return await apiCall('/get_projects', 'GET');
    };

    const updateProjectAPI = async (projectId) => {
        return await apiCall(`/NtoProject/${projectId.projectId}/`, 'PUT', projectId.updatedProject);
    };

    const createProject = async (project) => {
        return await apiCall('/NtoProject/', 'POST', project);
    };

    const deleteProjectsAPI = async (projectIds) => {
        const token = await getToken()
        const responses = await Promise.all(projectIds.map(projectId =>
            axios.delete(`${process.env.REACT_APP_API_URL}/NtoProject/${projectId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
        ));

        return responses.map(response => response.data);
    };
return { fetchProjectsAPI, updateProjectAPI, deleteProjectsAPI, createProject };
}