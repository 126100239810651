import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Select, Menu, MenuItem, Popover, ListSubheader } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Dialog, Paper, DialogTitle, DialogContent, DialogActions, IconButton, DialogContentText, Button} from '@mui/material';


import { useApiTasks } from './_fetchTasks';
import { useApiEvents } from './_fetchEvents';
import FollowupTaskDialog from './_TaskPropertiesDialog'; 
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'; // load plugin for 'Do' format
import { useUser } from "@clerk/clerk-react";
import CreateEventDialog from './_createEventPropertiesDialog';
import useStore from '../../store/Store'
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

dayjs.extend(advancedFormat); // use plugin




const theme = createTheme({                         // customizing the theme, background
    palette: {
        mode: 'dark',
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
            },
        },
    },
});


function EventsCalendar({ 
    events, 
    handleDrop, 
    openAsFullScreen,
    }) {

    const { user } = useUser();                                                 // get clerk user information
    const [openSnackbar, setOpenSnackbar] = useState(false);                    // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');     // snackbar for dialog message display

    //////////////////////////////////////////// FULL SCREEN CALENDAR DISPLAY MANAGEMENT ////////////////////////////////////////////
    const [openFullScreen, setOpenFullScreen] = useState(false);    // full screen state

    useEffect(() => {
        setOpenFullScreen(openAsFullScreen);
    }, [openAsFullScreen]);

    const handleFullScreenOpen = () => {                            // full screen open
        setOpenFullScreen(true);
    };
    const handleFullScreenClose = () => {                           // full screen close
        setOpenFullScreen(false);
    };

    //////////////////////////////////////////// EVENT COLORING ////////////////////////////////////////////
    const processedEvents = events.map(event => {
        const currentDate = new Date();
        const startDate = new Date(event.event_start);

        const endDate = new Date(event.event_end);

        let backgroundColor;
        if (endDate < currentDate && !event.complete) {
            backgroundColor = '#C95C76';
        } else if (endDate < currentDate && event.complete) {
            backgroundColor = '#457B82';
        } else if (endDate > currentDate && !event.complete) {
            backgroundColor = ''; // Default color
        } else if (endDate > currentDate && event.complete) {
            backgroundColor = '#359970';
        } else if (startDate < currentDate && endDate > currentDate && !event.complete) {
            backgroundColor = '#D5C567';
        } else if (startDate < currentDate && endDate > currentDate && event.complete) {
            backgroundColor = '#359970';
        }

        return { ...event, 
            title: event.event_title, // Rename event_title to title
            start: event.event_start, // Rename event_start to start
            end: event.event_end,     // Rename event_end to end
            backgroundColor,
            borderColor: backgroundColor
        };
    });


    //////////////////////////////////////////// COMPLETE EVENT / TASK and FOLLOW-UP TASK CREATION ////////////////////////////////////////////

    const { updateTaskAPI } = useApiTasks();                                                
    const fetchedTasksData = useStore(state => state.tasks);                                // fetch the tasks data from store
    const { updateTask, updateEvent, removeEvent, setEvents } = useStore()    
    const [selectedEventCompleted, setSelectedEventCompleted] = useState(null);             // set the selected event completed
    const [localTasksDataset, setLocalTasksDataset] = useState([]);                         // task local dataset
    const [followupTaskDialogOpen, setFollowupTaskDialogOpen] = useState(false);            // FollowupTaskDialog open state
    const [followupTaskToCreate, setFollowupTaskToCreate] = useState(null);                 // task to be passed to the FollowupTaskDialog component

    
    
    
    useEffect(() => {
        const filteredTasks = fetchedTasksData.filter(task => task.status !== 'Cn' && task.status !== 'Co');
        setLocalTasksDataset(filteredTasks);
    }, [fetchedTasksData]);


    const handleEventRelatedCompletedTask = async () => {                  // function to collect the updated task
        const originalTask = useStore.getState().tasks.find(task => task.id === selectedEventCompleted.event_taskId);
        const updatedTask = {
            taskId: selectedEventCompleted.event_taskId,
            name : selectedEventCompleted.event_title,
            status: 'Co',
            user: user.id,
        };
        handleFollowupTaskCreate(selectedEventCompleted)
        setSelectedEventCompleted(null);
        if (updatedTask) {
            updateTask({ ...originalTask, ...updatedTask });
            try {
                await updateTaskAPI({ taskId: updatedTask.taskId, updatedTask: updatedTask });
            } catch (error) {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to update task: " + error);
                updateTask(originalTask);  // Revert to original task

            }
        }
    };


    const handleFollowupTaskCreate = (eventCompleted) => {                                  // set the event related task to complete (locally) and pass a template to the FollowupTaskDialog component to create a follow-up task
        const completedTask = localTasksDataset.find(                                    // get the completed task ID
            task => task.id === eventCompleted.event_taskId);
        if (!completedTask) {
            // setErrorDialogOpen(true);
            return;
        }
        else {
            // const taskArrayWithUpdatedStatus = localTasksDataset.map(task =>                            // update the task status to completed
            //     task.id === completedTask.taskId ? { ...task, status: "Co" } : task
            // );
            // const updatedTasks = taskArrayWithUpdatedStatus.filter(                         // filter out the completed and cancelled tasks
            //     task => task.status !== 'Cn' && task.status !== 'Co');
            // setLocalTasksDataset(updatedTasks);
            const followupTaskTemplate = {                                                  // create a follow-up task template (retrieving the parent project)
                id: null,
                name: null,
                status: "Ns",
                parent: completedTask.parent,
                priority: "A",
            };
            setFollowupTaskToCreate(followupTaskTemplate);                                  // pass the follow-up task template to the TaskPropertiesDialog component
            setFollowupTaskDialogOpen(true);                                                // open the TaskPropertiesDialog component
            }
    };

    const handleFollowupTaskSave = (updatedTask) => {                                       // add the follow-up task to the local dataset when saved in case of a completed event
        const taskIndex = localTasksDataset.findIndex(task => task.id === updatedTask.id);

        let newTasks;
        if (taskIndex !== -1) {                             // task exists, update it
            newTasks = [...localTasksDataset];
            newTasks[taskIndex] = updatedTask;
        } else {                                            // task doesn't exist, add it
            newTasks = [...localTasksDataset, updatedTask];
        }

        const filteredTasks = newTasks.filter(task => task.status !== 'Cn' && task.status !== 'Co');
        setLocalTasksDataset(filteredTasks);
        setFollowupTaskDialogOpen(false)
    };

    //////////////////////////////////////////// EVENT DROP AND RESIZE ////////////////////////////////////////////
    const { deleteEventsAPI, updateEventAPI } = useApiEvents();
    const handleEventReceive = (info) => {                                                  // used to avoid duplicated item on calendar on drop
        info.revert();
    };

    const handleEventEdit = async (info) => {
        const eventToEdit = info.event || info;
    
        // Check if the event object is valid
        if (!eventToEdit) {
            console.error("Event object is missing.");
            return;
        }
    
        // Extract properties using fallbacks for different info structures
        const allDay = eventToEdit.allDay !== undefined ? eventToEdit.allDay : eventToEdit._def?.extendedProps?.event_allDay;
        const title = eventToEdit.event_title || eventToEdit.title || eventToEdit._def?.title;
        const taskId = eventToEdit.event_taskId || eventToEdit._def?.extendedProps?.event_taskId;
        const complete = eventToEdit.complete || eventToEdit._def?.extendedProps?.complete;
        const user = eventToEdit.user || eventToEdit._def?.extendedProps?.user;
    
        // Handle date and time conversion
        let newStart = eventToEdit.event_start || eventToEdit.start || eventToEdit._def?.extendedProps?.event_start;
        let newEnd = eventToEdit.event_end || eventToEdit.end || eventToEdit._def?.extendedProps?.event_end;
    
        if (!(newStart instanceof Date)) {
            newStart = new Date(newStart);
        }
        if (newEnd && !(newEnd instanceof Date)) {
            newEnd = new Date(newEnd);
        }
    
        newStart = newStart.toISOString().slice(0, -5) + 'Z';
        if (newEnd) {
            newEnd = newEnd.toISOString().slice(0, -5) + 'Z';
        } else {
            const defaultEnd = new Date(newStart);
            defaultEnd.setHours(defaultEnd.getHours() + 1);
            newEnd = defaultEnd.toISOString().slice(0, -5) + 'Z';
        }
    
        // Construct the updated event object
        const updatedEvent = {
            id: parseInt(eventToEdit.id, 10),
            event_title: title,
            event_start: newStart,
            event_end: newEnd,
            event_allDay: allDay,
            user: user,
            event_taskId: taskId,
            complete: complete
        };
    
        // Update the event in the store and attempt to sync with the backend
        updateEvent({ ...eventToEdit, ...updatedEvent });
        try {
            await updateEventAPI({ eventId: updatedEvent.id, updatedEvent: updatedEvent });
        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to update event: " + error);
            updateEvent(eventToEdit);  // Revert to original event on failure
        }
    };

    

    //////////////////////////////////////////// TIME SLOT DURATION DROPDOWN ////////////////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);                 // anchor position for the Popover
    const handleClickOnDurationButton = (event) => {                // set the anchor for the time slot
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDurationButton = () => {                       // close the times slot dropdown
        setAnchorEl(null);
    };
    const [slotDuration, setSlotDuration] = useState(localStorage.getItem('slotDuration') || '00:15:00');
    const handleSlotDurationChange = (event) => {
        setSlotDuration(event.target.value);
        localStorage.setItem('slotDuration', event.target.value);
        handleCloseDurationButton();
    };

    const [anchorCalendarMenu, setAnchorCalendarMenu] = useState(null);                 // anchor position for the Popover
    const handleCalendarMenuDropdown = (event) => {                 // set the anchor for the calendar menu dropdown
        setAnchorCalendarMenu(event.currentTarget);
        setIsCalendarMenuDropdownOpen(!isCalendarMenuDropdownOpen)
    };


    //////////////////////////////////////////// CALENDAR MENU DROPDOWN AND BUTTONS (SMALL SCREEN) ////////////////////////////////////////////
    const [headerCalendarToolbar, setHeaderCalendarToolbar] = useState({                // define contents of header buttons
        left: '',
        center: window.innerWidth < 400 
            ? 'prev,next timeSlot dropdownMenu' 
            : window.innerWidth < 1000 
                ? 'prev,next timeSlot fullScreen dropdownMenu' 
                : 'prev,next timeSlot fullScreen dayGridMonth,timeGridWeek,timeGridDay today',
        right: '',
    });
    useEffect(() => {                                                                   // set the header toolbar deployed or collapsed based on the window width
        const handleResize = () => {
            setHeaderCalendarToolbar({
                left: '',
                center: window.innerWidth < 400 
                    ? 'prev,next timeSlot dropdownMenu' 
                    : window.innerWidth < 1000 
                        ? 'prev,next timeSlot fullScreen dropdownMenu' 
                        : 'prev,next timeSlot fullScreen dayGridMonth,timeGridWeek,timeGridDay today',
                right: '',
            });
        };
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isCalendarMenuDropdownOpen, setIsCalendarMenuDropdownOpen] = useState(false);    // open or close the calendar menu dropdown
    const handleCloseCalendarMenu = () => {                                                 // close the calendar menu dropdown
        setIsCalendarMenuDropdownOpen(false);
    };

    const customCalendarButtons = {                                                     // define the custom buttons for the calendar
        dropdownMenu: {
            text: '...',
            click: handleCalendarMenuDropdown
        },
        timeSlot: {
            text: 'Time Slot',
            click: handleClickOnDurationButton
        },
        fullScreen: {
            text: 'Full Screen',
            click: handleFullScreenOpen
        },
    };

    //////////////////////////////////////////// CALENDAR VIEWS MANAGEMENT ////////////////////////////////////////////
    const calendarRefNormalScreen = useRef(null);                                       // used to access the calendar API
    const calendarRefFullScreen = useRef(null);                                         // used to access the calendar API
    const initialView = useState(localStorage.getItem('initialView') || 'timeGridWeek')[0];
    const changeView = (view) => {
        
        const calendarNormalScreenApi = calendarRefNormalScreen.current?.getApi();
        const calendarFullScreenApi = calendarRefFullScreen.current?.getApi();
        calendarNormalScreenApi?.changeView(view);
        calendarFullScreenApi?.changeView(view);
    };

    const handleToday = () => {
        const calendarNormalScreenApi = calendarRefNormalScreen.current?.getApi();
        const calendarFullScreenApi = calendarRefFullScreen.current?.getApi();

        calendarNormalScreenApi?.today();
        calendarFullScreenApi?.today();
    };

    const handleDayGridMonth = () => changeView('dayGridMonth');
    const handleTimeGridWeek = () => changeView('timeGridWeek');
    const handleTimeGridDay = () => changeView('timeGridDay');
    
    //////////////////////////////////////////// Calendar ////////////////////////////////////////////

    const renderFullCalendar = (calendarRef) => (
        <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={headerCalendarToolbar}
            initialView={initialView}
            slotDuration={slotDuration}
            droppable={true}
            editable={true}
            slotEventOverlap={false}
            // eventSources={[{ events: processedEvents }]}
            eventSources={[{ events: processedEvents  }]}
            drop={handleDrop}
            eventReceive={handleEventReceive}
            eventDrop={handleEventEdit}
            eventResize={handleEventEdit}
            height="100%"
            width="100%"
            firstDay={1}
            nowIndicator={true}
            customButtons={customCalendarButtons}
            eventClick={handleEventCalendarClick}
            dayMaxEventRows={4}
            datesSet={(info) => localStorage.setItem('initialView', info.view.type)}
            dateClick={(info) => handleEmptyEventClick(info)} // new event listener
            />
    );
    //////////////////////////////////////////// EVENT DIALOG BOX (WHEN CLICK ON EVENT) ////////////////////////////////////////////
    const [eventMainDialogOpen, setEventMainDialogOpen] = useState(false);
    const [selectedEventInDialog, setSelectedEventInDialog] = useState(null);
    
    const handleEventCalendarClick = ({ event }) => {
        setSelectedEventInDialog(event);
        setEventMainDialogOpen(true);
    };



    /////////////////////////////////////////////// EDITING EVENT ////////////////////////////////////////////
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    
    const [scheduleItDialogOpen, setScheduleItDialogOpen] = useState(false);
    const [eventName, setEventName] = useState('');
    const [formattedEndDate, setFormattedEndDate] = useState('');
    const [formattedStartDate, setFormattedStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [allDay, setAllDay] = useState('');
    const [eventID, setEventID] = useState('');



    
    const handleEditEvent = () => {
        setEventName(selectedEventInDialog.title);
        const endDate = !allDay 
            ? selectedEventInDialog.end
            : dayjs(selectedEventInDialog._instance.range.end).subtract(1, 'day').toDate();
        setFormattedEndDate(formatDate(endDate));
        setFormattedStartDate(formatDate(selectedEventInDialog.start));
        setStartTime(dayjs(selectedEventInDialog.start));
        setEndTime(dayjs(selectedEventInDialog.end));
        setAllDay(selectedEventInDialog.allDay);
        setEventID(selectedEventInDialog._def.publicId);
        setScheduleItDialogOpen(true);
        setEventMainDialogOpen(false);
    };

    const handleEmptyEventClick = (date) => {
        setEventName('');
        setFormattedEndDate(formatDate(date.date));
        setFormattedStartDate(formatDate(date.date));
        setStartTime(dayjs(date.date));
        setEndTime(dayjs(date.date).add(1, 'hour'));
        setAllDay(false);
        setEventID(null);
        setScheduleItDialogOpen(true);
    };


    /////////////////////////////////////////////// DELETE EVENT ////////////////////////////////////////////
    
    const [selectedEventToDelete, setSelectedEventToDelete] = useState(null);       // set the selected event to be deleted


    const handleDeleteEvent = async () => {                              // Function to handle the delete button click
        const originalEvents = useStore.getState().events;
        removeEvent(selectedEventToDelete._def.publicId);
        try {
            await deleteEventsAPI([selectedEventToDelete._def.publicId]);
        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to delete task: " + error);
            setEvents(originalEvents);  // Restore the original tasks

        }
        setSelectedEventToDelete(null)
        setEventMainDialogOpen(false);

    };




    //////////////////////////////////////////// COMPLETED EVENT STATUS CHANGE ////////////////////////////////////////////
    function handleCompletedStatusChange() {                        // swap event complete status, update event dataset locally and in the API

        const updatedEvents = events.map(event =>                                                                       // swap the complete status of the event                     
            event.id === Number(selectedEventInDialog._def.publicId)
                ? { ...event, complete: !selectedEventInDialog._def.extendedProps.complete } 
                : event
        );                                                                           // update the events dataset                                

        const updatedEvent = updatedEvents.find(event => event.id === Number(selectedEventInDialog._def.publicId));

        handleEventEdit({ ...selectedEventInDialog, event: updatedEvent });                                             // update the event in the API

        console.log('selectedEventInDialog:', selectedEventInDialog)
        if (!selectedEventInDialog._def.extendedProps.complete && selectedEventInDialog._def.extendedProps.event_taskId) {
            setSelectedEventCompleted(updatedEvent);
        }
        setEventMainDialogOpen(false);
    }
    
    
    return (
        <ThemeProvider theme={theme}>
            <div ref={anchorCalendarMenu} style={{ display: 'none' }} />

            {/* calendar menu */}
            <Menu
                anchorEl={anchorCalendarMenu}
                open={isCalendarMenuDropdownOpen}
                onClose={handleCloseCalendarMenu}
            >
                <MenuItem onClick={() => { console.log('Month View clicked'); handleDayGridMonth(); handleCloseCalendarMenu(); }}>Month View</MenuItem>
                <MenuItem onClick={() => { console.log('Week View clicked'); handleTimeGridWeek(); handleCloseCalendarMenu(); }}>Week View</MenuItem>
                <MenuItem onClick={() => { console.log('Day View clicked'); handleTimeGridDay(); handleCloseCalendarMenu(); }}>Day View</MenuItem>
                <MenuItem onClick={() => { console.log('Today clicked'); handleToday(); handleCloseCalendarMenu(); }}>Today</MenuItem>
            </Menu>

            {/* event dialog box */}
            <Dialog open={eventMainDialogOpen} onClose={() => setEventMainDialogOpen(false)}>
                <DialogTitle>{selectedEventInDialog?.title}</DialogTitle>
                <DialogContent>
                    {selectedEventInDialog && (
                        <>
                            Start: {selectedEventInDialog._def.allDay 
                                ? dayjs(selectedEventInDialog.start).format('dddd, D MMMM')
                                : dayjs(selectedEventInDialog.start).format('dddd, D MMMM⋅h:mma')}
                            <br/>
                            End: {selectedEventInDialog._def.allDay 
                                ? dayjs(selectedEventInDialog.end).subtract(1, 'day').format('dddd, D MMMM')
                                : (dayjs(selectedEventInDialog.start).isSame(dayjs(selectedEventInDialog.end), 'day')
                                    ? dayjs(selectedEventInDialog.end).format('h:mma')
                                    : dayjs(selectedEventInDialog.end).format('dddd, D MMMM⋅h:mma'))}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={handleEditEvent}><EditIcon /></IconButton>
                    <IconButton onClick={() => setSelectedEventToDelete(selectedEventInDialog)}><DeleteIcon /></IconButton>
                    <IconButton onClick={handleCompletedStatusChange}><DoneIcon /></IconButton>
                    <IconButton onClick={() => setEventMainDialogOpen(false)}><CloseIcon /></IconButton>
                </DialogActions>
            </Dialog>

            {/* edit event dialog */}
            <CreateEventDialog 
                scheduleItDialogOpen={scheduleItDialogOpen}             //sent true of false value to open or close the dialog
                setScheduleItDialogOpen={setScheduleItDialogOpen}       //received function to set the dialog open or close
                taskName={eventName}                                     //sent task name to be scheduled from taskToProcess
                formattedEndDate={formattedEndDate}                   //sent task deadline to be scheduled
                user={user}                                             //sent user information
                formattedStartDate={formattedStartDate}
                startTime = {startTime}
                endTime = {endTime}
                allDay = {allDay}
                eventID = {eventID}
                formattedDeadline= {null}
            />
            

            {/* delete event dialog */}
            {selectedEventToDelete && (
                <Dialog
                    open={true}
                    onClose={() => setSelectedEventToDelete(null)}
                >
                    <DialogTitle>Delete Event</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this event?
                        </DialogContentText>
                    </DialogContent>    
                    <DialogActions>
                        <Button onClick={() => setSelectedEventToDelete(null)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteEvent} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* complete event dialog */}
            {selectedEventCompleted && (
                <Dialog
                    open={true}
                    onClose={() => setSelectedEventCompleted(null)}
                >
                    <DialogTitle>Event completed</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to set the related task as completed?
                        </DialogContentText>
                    </DialogContent>    
                    <DialogActions>
                        <Button onClick={() => setSelectedEventCompleted(null)} color="primary">
                            No
                        </Button>
                        <Button onClick={handleEventRelatedCompletedTask} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <FollowupTaskDialog 
                open={followupTaskDialogOpen}
                onClose={() => setFollowupTaskDialogOpen(false)}
                task={followupTaskToCreate}
                onSave={handleFollowupTaskSave}
            />
            {/* fullscreen calendar */}
            <Dialog
                open={openFullScreen}
                onClose={handleFullScreenClose}
                PaperComponent={Paper}
                disableAutoFocus
                PaperProps={{ style: { width: '90vw', height: '90vh', overflow: 'auto', maxWidth: 'none', backgroundColor: 'rgba(17, 24, 39)', backgroundImage: 'none' } }}
            >
            {renderFullCalendar(calendarRefFullScreen)}
            </Dialog>

            {/* normal calendar */}
            {renderFullCalendar(calendarRefNormalScreen)}

            {/* time slot duration dropdown */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDurationButton}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                BackdropComponent={Backdrop} // Use the Backdrop component
            >
                <Select
                    open={Boolean(anchorEl)} // Open the Select dropdown when the Popover is open
                    value={slotDuration}
                    onChange={handleSlotDurationChange}
                >
                    <ListSubheader>Time Slot</ListSubheader>
                    <MenuItem value="00:05:00">5 minutes</MenuItem>
                    <MenuItem value="00:15:00">15 minutes</MenuItem>
                    <MenuItem value="00:30:00">30 minutes</MenuItem>
                    <MenuItem value="01:00:00">60 minutes</MenuItem>
                </Select>
            </Popover>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {dialogMessageSnackBar}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}

export default EventsCalendar;
