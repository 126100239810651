// ScoreTable.js
import React, { useState } from 'react';
import PrioritySelect from './_PrioritySelect';
import { useApiProjects } from './_fetchProjects';
import { useMutation } from 'react-query';
import { Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ScoreTable = ({ data }) => {
    const { updateProjectAPI } = useApiProjects();
    const projectSortedByScore = [...data].sort((a, b) => b.project_score - a.project_score);

    const [projectsWithNewPriorities, setProjectsWithNewPriorities] = useState(
        data.reduce((acc, project) => ({
            ...acc,
            [project.id]: {
                project_priority: project.project_priority,
                user: project.user,
                project_status: project.project_status,
                project_name: project.project_name,
            }
        }), {})
    );

    const [open, setOpen] = useState(false);

    const updateProjectMutation = useMutation(updateProjectAPI, {
        onSuccess: () => {
            setOpen(true);  // Open the Snackbar
            setTimeout(() => {
                navigate('/');  // Navigate to the homepage
            }, 2000);  // Navigate after 2 seconds
        },
    });

    const navigate = useNavigate();

    const savePriorities = () => {
        for (const projectId in projectsWithNewPriorities) {
            const project = data.find(project => project.id === projectId);
            const updatedProject = {
                ...project,
                project_priority: projectsWithNewPriorities[projectId].project_priority,
                user: projectsWithNewPriorities[projectId].user,
                project_status: projectsWithNewPriorities[projectId].project_status,
                project_name: projectsWithNewPriorities[projectId].project_name,
            };
            updateProjectMutation.mutate({ projectId, updatedProject });
        }
    };

return (
    <div>
        <table className="divide-y divide-gray-700 mx-auto w-11/12 table-auto">
            <thead className="bg-gray-800">
                <tr>
                    <th className="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase tracking-wider">
                        Project Name
                    </th>
                    <th className="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase tracking-wider">
                        Score
                    </th>
                    <th className="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase tracking-wider">
                        Previous Priority
                    </th>
                    <th className="px-2 py-3 text-center text-xs font-medium text-gray-200 uppercase tracking-wider">
                        New Priority
                    </th>
                </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-700">
                {projectSortedByScore.map((project) => (
                    <tr key={project.id}>
                        <td className="px-2 py-4 text-sm text-gray-300 text-center">
                            {project.project_name}
                        </td>
                        <td className="px-2 py-4 text-sm text-gray-300 text-center">
                            {project.project_score}
                        </td>
                        <td className="px-2 py-4 text-sm text-gray-300 text-center">
                            {project.project_priority}
                        </td>
                        <td className="px-2 py-4 text-sm text-gray-300 text-center">
                            <PrioritySelect 
                                value={projectsWithNewPriorities[project.id]?.project_priority || ''} 
                                onChange={(e) => {
                                    setProjectsWithNewPriorities(prevPriorities => ({
                                        ...prevPriorities,
                                        [project.id]: {
                                            ...prevPriorities[project.id],
                                            project_priority: e.target.value
                                        }
                                    }));
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        <button onClick={savePriorities} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded mx-auto block">
            Save
        </button>
            <Snackbar open={open} autoHideDuration={2000} onClose={() => setOpen(false)}>
    <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
        Projects updated successfully!
    </Alert>
</Snackbar>
        </div>
);
};

export default ScoreTable;