import React, { useState } from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, TextField  } from '@mui/material';
import QuickTaskForm from './QuickTaskForm';
import { useNavigate } from 'react-router-dom';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { QueuePlayNext } from '@mui/icons-material';
import { 
    Edit as EditIcon, 
    FilterList as FilterListIcon, 
    Work as WorkIcon, 
    CompareArrows as CompareArrowsIcon, 
    List as ListIcon, 
    CalendarToday as CalendarTodayIcon, 
    LocationOn as LocationOnIcon, 
    Group as GroupIcon,
    // QueuePlayNextIcon as NextIcon, 
} from '@mui/icons-material';
import { useUser, SignedIn, UserButton } from '@clerk/clerk-react';


const SideMenu = () => {
    const { user } = useUser();

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openQuickTaskForm, setOpenQuickTaskForm] = useState(false);

    const actions = [
        { 
            icon: () => <EditIcon />, 
            name: 'Quick Task Entry', 
            action: () => setOpenQuickTaskForm(true) 
        },
        { icon: () => <FilterListIcon />, name: 'New Task Organizer', path: '/newtaskorganizer' },
        { icon: () => <QueuePlayNext />, name: 'Next Task capture', path: '/nexttaskcapture' },
        { icon: () => <WorkIcon />, name: 'Manage your Projects', path: '/prioritizerProjects' },
        { icon: () => <CompareArrowsIcon />, name: 'Compare your projects', path: '/comparator' },
        { icon: () => <ListIcon />, name: 'Manage your Tasks', path: '/prioritizerTasks' },
        { icon: () => <CalendarTodayIcon />, name: 'Calendar', path: '/calendar' },
        { icon: () => <LocationOnIcon />, name: 'Contexts', path: '/contexts' },
        { icon: () => <GroupIcon />, name: 'Assignees', path: '/assignees' },
        { 
            icon: () => (
                <SignedIn>
                    <UserButton />
                </SignedIn>
            ), 
            name: 'User Account', 
            action: () => {} 
        },
    ];

    const [openContactForm, setOpenContactForm] = useState(false);
    const handleSend = async () => {
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;
    
        const response = await fetch('https://your-django-backend.com/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, message }),
        });
    
        if (response.ok) {
            // Close the dialog if the request was successful
            setOpenContactForm(false);
        } else {
            // Handle error
        }
    };



    if (!user) return null;

    return (
        <>
        {/* side menu */}
        <div style={{ 
            position: 'fixed', 
            zIndex: 9999, 
            backgroundColor: open ? 'rgba(12, 12, 13, 0.5)' : 'transparent',
            pointerEvents: open ? 'auto' : 'none',
            top: 7, // Add this line
            left: 10 // And this line
        }}>
            {!openQuickTaskForm && (
                <SpeedDial
                    ariaLabel="SpeedDial"
                    icon={<SpeedDialIcon />}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    direction="down"
                    style={{ pointerEvents: 'none' }} // Ensure the SpeedDial itself is always interactive
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon()}
                            tooltipTitle={action.name}
                            onClick={action.action || (() => navigate(action.path))}
                        />
                    ))}
                </SpeedDial>
            )}
            <Dialog 
                open={openQuickTaskForm} 
                onClose={() => setOpenQuickTaskForm(false)}
                PaperProps={{
                    sx: {
                    backgroundImage: 'none',                    // remove the default color that create ugly grey corners
                    },
                }}>
                <QuickTaskForm />
            </Dialog>
        </div>

        {/* contact developer button */}
        <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setOpenContactForm(true)}
            style={{ position: 'fixed', right: 10, bottom: 10, zIndex: 9999 }}
        >
            Contact Developer
        </Button>
        <Dialog open={openContactForm} onClose={() => setOpenContactForm(false)}>
            <DialogTitle>Contact Developer</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="message"
                    label="Message"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenContactForm(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSend} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default SideMenu;