import React, { useState, useEffect, useMemo } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, CircularProgress, List, ListItemText, ListItemButton, OutlinedInput, Slider, Snackbar, Alert } from '@mui/material';
import { useUser } from "@clerk/clerk-react";
import debounce from 'lodash.debounce';
import BaseLayout from './baselayout';
import PrioritySelect from './subComponents/_PrioritySelect';
import DatePicker from './subComponents/_DatePicker';
import { commonStyles } from './subComponents/_commonStyles';
import { getProjectPriority, getTaskDeadline, getTodayDate, getTaskPriority, calculateUrgency, calculateProjectPriorityScore, calculateTaskPriorityScore, calculateCompoundPriority } from './subComponents/_prioritizerCompoundPriority';
import TaskTypeSelection from './subComponents/_ntoTaskTypeSelection';
import ContextDialogBox from './subComponents/_contextDialogBox';
import AssigneeDialogBox from './subComponents/_assigneeDialogBox';
import useStore from '../store/Store'
import { useApiTasks } from './subComponents/_fetchTasks';
import { useApiProjects } from './subComponents/_fetchProjects';




const NewTaskOrganizer = React.memo(() => {
    const headerContent = "New Task Organizer Wizard";
    const { user } = useUser();                         // get clerk user information
    const [currentTaskToBeProcessed, setCurrentTaskToBeProcessed] = useState(null);
    const [inputType, setInputType] = useState('');
    const [priority, setPriority] = useState('A');
    const [effort, setEffort] = useState(0);
    const todayDate = useMemo(() => new Date(), []);
    const todayFormattedDate = useMemo(() => {
        const year = todayDate.getFullYear();
        const month = String(todayDate.getMonth() + 1).padStart(2, '0');
        const date = String(todayDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${date}`;
        }, [todayDate]);
    const [deadline, setDeadline] = useState(todayFormattedDate);
    const [selectedContextForTheTask, setSelectedContextForTheTask] = useState('');
    const [selectedAssigneeForTheTask, setSelectedAssigneeForTheTask] = useState('');
    const [relatedProjectForTheTask, setRelatedProjectForTheTask] = useState('');
    const [nextActionOfTheProject, setNextActionForTheProject] = useState('');
    const [projectFilterText, setProjectFilterText] = useState('');
    const [inputName, setInputName] = useState('');
    const [debouncedProjectFilterText, setDebouncedProjectFilterText] = useState(projectFilterText);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [newTasksToBeProcessed, setNewTasksToBeProcessed] = useState([]);
    const storeTaskList = useStore(state => state.tasks);
    const storeContextsList = useStore(state => state.contexts);
    const storeAssigneesList = useStore(state => state.assignees);
    const storeProjectList = useStore(state => state.projects);
    const isLoadingEntries = useStore(state => state.isLoading);
    const { updateTask, addProject, removeTask, setTasks, addTask } = useStore()
    const { updateTaskAPI, deleteTasksAPI, createTask } = useApiTasks();  
    const { createProject } = useApiProjects(); 
    const [openSnackbar, setOpenSnackbar] = useState(false);                    // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');     // snackbar for dialog message display

    useEffect(() => {                                   // Update debouncedProjectFilterText after projectFilterText changes, with a delay of 300ms
        const debouncedSetFilterText = debounce(setDebouncedProjectFilterText, 300);
        debouncedSetFilterText(projectFilterText);
        return () => debouncedSetFilterText.cancel();
    }, [projectFilterText]);

    const projectsListFiltered = useMemo(() => {        // Filter the projects list based on the debouncedProjectFilterText
        return storeProjectList.filter(project => {
            if (typeof project.project_name === 'string') {
                return project.project_name.toLowerCase().includes(debouncedProjectFilterText.toLowerCase());
            }
            return false;
        });
    }, [storeProjectList, debouncedProjectFilterText]);

    useEffect(() => {                                   // Filter the new tasks to be processed (only the tasks that are new)
        const filteredTasks = storeTaskList.filter(task => task.new_task === true);
        setNewTasksToBeProcessed(filteredTasks);
    }, [storeTaskList]);

    useEffect(() => {                                   // Set the current task to be processed and the input fields
        if (newTasksToBeProcessed.length > 0) {
            const task = newTasksToBeProcessed[0];
            setCurrentTaskToBeProcessed(task);
            setInputName(task.name);

            if (task.parent !== null) {
                const project = storeProjectList.find(p => p.id === task.parent);
                const projectName = project ? project.project_name : '';
                setInputType('task');
                setRelatedProjectForTheTask(task.parent);
                setProjectFilterText(projectName);
                setPriority(task.priority || 'A');
                setDeadline(task.deadline || todayFormattedDate);
            } else {
                setInputType('');
                setRelatedProjectForTheTask('');
                setProjectFilterText('');
                setPriority('A');
                setEffort(0);
                setDeadline(todayFormattedDate);
                setNextActionForTheProject('');
            }
        }
    }, [newTasksToBeProcessed, storeProjectList, todayFormattedDate]);


    //////////////////////////////////////////////////////////////////////////  FORM Event Handlers  //////////////////////////////////////////////////////////////////////////
    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
    };
    const handleEffortChange = (event) => {
        setEffort(event.target.value);
    };
    const handleDeadlineChange = (event) => {
        setDeadline(event.target.value);
    };
    const handleContextChange = (event) => {
        setSelectedContextForTheTask(event.target.value);
    };
    const handleAssigneeChange = (event) => {
        setSelectedAssigneeForTheTask(event.target.value);
    };
    const handleRelatedProjectChange = (projectId) => {
        setRelatedProjectForTheTask(projectId);
    };
    const handleNextActionToBeAdded = (event) => {
        setNextActionForTheProject(event.target.value);
    };


    const handleProcessNext = async () => {
        setIsLoadingSubmit(true);
        try {
            // Calculate the compound priority value
            const projectPriority = getProjectPriority(relatedProjectForTheTask, storeProjectList);
            const taskDeadline = getTaskDeadline(deadline);
            const todayDate = getTodayDate(); 
            const taskPriority = getTaskPriority(priority);
            const urgency = calculateUrgency(taskDeadline, todayDate);
            const projectPriorityScore = calculateProjectPriorityScore(projectPriority);
            const taskPriorityScore = calculateTaskPriorityScore(taskPriority);
            const compoundPriorityValue = calculateCompoundPriority(taskPriorityScore, projectPriorityScore, urgency);
        

            // Prepare the body of the POST request
            const body = inputType === 'task' ? {           // body if it's a task
                name: inputName,
                user: user.id,
                effort: effort,
                id: currentTaskToBeProcessed.id,
                priority: priority,
                deadline: deadline,
                context: selectedContextForTheTask,
                assignee : selectedAssigneeForTheTask,
                parent: relatedProjectForTheTask,
                new_task: false,
                compound_priority: compoundPriorityValue,
            } : {                                           // body if it's a project
                project_name: inputName,
                user: user.id,
                project_priority: priority,
                project_deadline: deadline,
                project_status: 'Ns',
            };
    
            if (inputType === 'task') {
                const originalTask = useStore.getState().tasks.find(task => task.id === currentTaskToBeProcessed.id);
                updateTask({ ...originalTask, ...body });
                try {
                    await updateTaskAPI({ taskId: currentTaskToBeProcessed.id, updatedTask: body });
                } catch (error) {
                    setOpenSnackbar(true);
                    setDialogMessageSnackBar("Failed to update task: " + error);
                    updateTask(originalTask);  // Revert to original task

                }}
            else if (inputType === 'project') {// if it's a project, create the project, delete the current task, make an optimistic update
                try {
                    const projectApiResponse = await createProject(body);
                    if (projectApiResponse) {
                        addProject(projectApiResponse);
                        const originalTasks = useStore.getState().tasks;
                        removeTask([currentTaskToBeProcessed.id]);
                        try {
                            await deleteTasksAPI([currentTaskToBeProcessed.id]);
                            if (inputType === 'project' && nextActionOfTheProject !== '') {                                 // if it's a project and there's a next action
                                const projectFirstTask = {
                                    name: nextActionOfTheProject,
                                    user: user.id,
                                    new_task: true,
                                    parent: projectApiResponse.id,
                                }
                                try {
                                    const response = await createTask(projectFirstTask);
                                    if (response) {
                                        const newTask = {
                                            ...response,
                                            id: response.task_id,
                                            name: response.task_name
                                        };
                                        delete newTask.task_id;
                                        addTask(newTask);
                        
                                    } else {
                                        setOpenSnackbar(true);
                                        setDialogMessageSnackBar("Failed to create task: No ID returned from the API");
                                    }
                        
                                } catch (error) {
                                    setOpenSnackbar(true);
                                    setDialogMessageSnackBar("Failed to create task: " + error);
                                }
                
                            }
                        } catch (error) {
                            setOpenSnackbar(true);
                            setDialogMessageSnackBar("Failed to delete task: " + error);
                            setTasks(originalTasks);  // Restore the original tasks
                
                        }
                    } else {
                        setOpenSnackbar(true);
                        setDialogMessageSnackBar("Failed to create project: No ID returned from the API");
                    }
        
                } catch (error) {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to create project: " + error);
            }}
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setInputType([]);                                                                                               // reset the input fields   
            setCurrentTaskToBeProcessed(newTasksToBeProcessed.length > 1 ? newTasksToBeProcessed[1] : null);                // set the next task to be processed
            setNextActionForTheProject([]);
            setSelectedContextForTheTask('');
            setSelectedAssigneeForTheTask('');
            setDeadline(todayFormattedDate);                                       
            setIsLoadingSubmit(false);                                                                           // stop the loading spinner
        }
    };


    
    const handleDelete = async () => {
        const originalTasks = useStore.getState().tasks;
        removeTask([currentTaskToBeProcessed.id]);
        try {
            await deleteTasksAPI([currentTaskToBeProcessed.id]);
        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to delete task: " + error);
            setTasks(originalTasks);  // Restore the original tasks

        }
    };
    
    return (
        <BaseLayout headerContent={headerContent}>
            {isLoadingEntries || isLoadingSubmit ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                    {isLoadingEntries && <p>Fetching new entries...</p>}
                </div>
            ) : (
                newTasksToBeProcessed.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full bg-gray-800 text-white px-4 sm:px-0">
                    <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-8">
                        <span className="text-yellow-300">Nothing to be processed.</span>
                    </h1>
                    <h2 className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-center">
                        Add new entries, review your projects, tasks or schedule.
                    </h2>
                </div>
            ) : (
            <div className="flex flex-col text-white p-6 space-y-4 justify-center">
                    <div>
                        <div className="flex flex-col sm:flex-row justify-center mx-auto space-y-4 sm:space-y-6 space-x-0 sm:space-x-12 max-w-screen-sm">
                            <div className="flex flex-col space-y-4 w-full sm:w-auto">
                                <h1 className="text-2xl mb-4" style={{ color: "#579BFC" }}>
                                    {newTasksToBeProcessed.length} {newTasksToBeProcessed.length === 1 ? 'entry' : 'entries'} to be processed
                                </h1>
                                <h2 className="text-lg mb-4">Current: {currentTaskToBeProcessed?.name}</h2>
                                {currentTaskToBeProcessed && (
                                    <TextField
                                        // className="form-input mt-1 block w-full"
                                        label="Rename your task"
                                        value={inputName}
                                        sx={commonStyles}
                                        onChange={(e) => setInputName(e.target.value)}
                                    />
                                )}
                            </div>
                            <div className="flex flex-col space-y-4 w-full sm:w-auto">
                                <h2 className="text-lg mb-4 sm:mb-0" style={{ color: "#579BFC" }}>
                                    What is this?
                                </h2>
                                <TaskTypeSelection inputType={inputType} setInputType={setInputType} />
                            </div>
                        </div>
                    </div>
    
                    {inputType === "task" && (
                        <>
                            <div className="flex justify-center  space-y-2 space-x-4 sm:mx-auto sm:space-x-12">
                                <div className="flex-1 flex flex-col space-y-4">
                                    <TextField
                                        value={projectFilterText}
                                        onChange={(e) => setProjectFilterText(e.target.value)}
                                        label="Find a related project"
                                        sx={commonStyles}
                                    />
    
                                    <List
                                        sx={{
                                            maxHeight: "200px",
                                            overflow: "auto",
                                            border: "1px solid #9699A6",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        {projectsListFiltered.map((project) => (
                                            <ListItemButton
                                                key={project.id}
                                                selected={relatedProjectForTheTask === project.id}
                                                onClick={() => handleRelatedProjectChange(project.id)}
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "#18283b",
                                                    },
                                                }}
                                            >
                                                <ListItemText primary={project.project_name} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </div>
                                <div className="flex-1 flex flex-col space-y-4">
                                    <div className="flex flex-col space-y-4">
                                        <div>
                                            <div className="flex items-center space-x-4">
                                                <PrioritySelect value={priority} onChange={handlePriorityChange} />
                                                <TextField
                                                    value={effort}
                                                    label="Effort (min)"
                                                    onChange={handleEffortChange}
                                                />
                                            </div>
                                            <Slider
                                                value={effort}
                                                min={0}
                                                max={60}
                                                step={5}
                                                onChange={handleEffortChange}
                                                valueLabelDisplay="auto"
                                            />
                                        {/* </Box> */}
                                        </div>
                                    <DatePicker value={deadline} onChange={handleDeadlineChange} />
                                </div>
                                <div className="flex flex-col space-y-4">
                                    <FormControl variant="outlined" sx={{ ...commonStyles, minWidth: 180 }}>
                                        <InputLabel id="context-label">Context</InputLabel>
                                        <Select
                                            label="Context"
                                            value={selectedContextForTheTask}
                                            onChange={handleContextChange}
                                            input={<OutlinedInput label="Context" />}
                                        >
                                            {storeContextsList.map((context) => (
                                                <MenuItem key={context.id} value={context.id}>
                                                    {context.name}
                                                </MenuItem>
                                            ))}
                                            <ContextDialogBox
                                                setSelectedContext={setSelectedContextForTheTask}
                                                // setContextsList={setContextsList}
                                            />
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" sx={{ ...commonStyles, minWidth: 180 }}>
                                        <InputLabel id="assignee-label">Assignee</InputLabel>
                                        <Select
                                            label="Assignee"
                                            value={selectedAssigneeForTheTask}
                                            onChange={handleAssigneeChange}
                                            input={<OutlinedInput label="Assignee" />}
                                        >
                                            {storeAssigneesList.map((assignee) => (
                                                <MenuItem key={assignee.id} value={assignee.id}>
                                                    {assignee.name}
                                                </MenuItem>
                                            ))}
                                            <AssigneeDialogBox
                                                setSelectedAssignee={setSelectedAssigneeForTheTask}
                                                // setAssigneesList={setAssigneesList}
                                            />
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>
                        </div>
                        <Button onClick={handleProcessNext}>Process & Next</Button>
                    </>
                )}

                {inputType === "project" && (
                    <div className="flex flex-col sm:flex-row justify-between max-w-screen-lg mx-auto space-y-4">
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-row space-x-4">
                                <PrioritySelect value={priority} onChange={handlePriorityChange} />
                                <DatePicker value={deadline} onChange={handleDeadlineChange} />
                            </div>
                            <TextField
                                value={nextActionOfTheProject}
                                label="First Action"
                                onChange={handleNextActionToBeAdded}
                                sx={{ ...commonStyles, minWidth: 100 }}
                            />
                            <Button onClick={handleProcessNext}>Process & Next</Button>
                        </div>
                    </div>
                )}
                {inputType === "nonActionable" && (
                    <Button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                )}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        {dialogMessageSnackBar}
                    </Alert>
                </Snackbar>
            </div>
        )
    )}
    </BaseLayout>
);
});

export default NewTaskOrganizer;