import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@mui/material';
import { useApiAssignees } from './_fetchAssignees';
import { useUser } from "@clerk/clerk-react";

import useStore from '../../store/Store'
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

const AssigneeDialogBox = ({ setSelectedAssignee }) => {
    const { createAssignee } = useApiAssignees();         //CRUD API
    const { addAssignee } = useStore()                   // CRUD STORE
    const [openSnackbar, setOpenSnackbar] = useState(false);                // snackbar for error display
    const [dialogMessageSnackBar, setDialogMessageSnackBar] = useState('');  // snackbar for dialog message display

    const [addNewAssigneeDialogOpen, setAddNewAssigneeDialogOpen] = useState(false);
    const [newAssigneeNameToBeAdded, setNewAssigneeNameToBeAdded] = useState("");
    const [newAssigneeDescriptionToBeAdded, setNewAssigneeDescriptionToBeAdded] = useState("");
    const { user } = useUser();                         // get clerk user information

    const handleAddAssigneeByOpeningDialogBox = useCallback(() => {
        setAddNewAssigneeDialogOpen(true);
    }, []);

    const handleCloseAddAssigneeDialogBox = useCallback(() => {
        setAddNewAssigneeDialogOpen(false);
    }, []);

    const handleNameChange = useCallback((event) => {
        setNewAssigneeNameToBeAdded(event.target.value || "");
    }, []);

    const handleDescriptionChange = useCallback((event) => {
        setNewAssigneeDescriptionToBeAdded(event.target.value || "");
    }, []);

    const handleSubmitNewAssignee = useCallback(async () => {
        const newAssignee = {
            name: newAssigneeNameToBeAdded,
            description: newAssigneeDescriptionToBeAdded,
            user: user.id,
        };
        try {
            const response = await createAssignee(newAssignee);
            if (response && response.id) {
                const newAssignee = {
                    ...response,
                    id: response.id,
                    name: response.name
                };
                
                addAssignee(newAssignee);

            } else {
                setOpenSnackbar(true);
                setDialogMessageSnackBar("Failed to create assignee: No ID returned from the API");
            }

        } catch (error) {
            setOpenSnackbar(true);
            setDialogMessageSnackBar("Failed to create assignee: " + error);
        }

        setNewAssigneeDescriptionToBeAdded("");
        setNewAssigneeNameToBeAdded("");
        setAddNewAssigneeDialogOpen(false);

    }, [newAssigneeNameToBeAdded, newAssigneeDescriptionToBeAdded, user.id, createAssignee, addAssignee]);

    return (
        <div>
            <Button onClick={handleAddAssigneeByOpeningDialogBox}>+ add assignee</Button>
            <Dialog open={addNewAssigneeDialogOpen} onClose={handleCloseAddAssigneeDialogBox}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Enter the name and description for the new assignee.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newAssigneeNameToBeAdded}
                        onChange={handleNameChange}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        value={newAssigneeDescriptionToBeAdded}
                        onChange={handleDescriptionChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddAssigneeDialogBox}>Cancel</Button>
                    <Button onClick={handleSubmitNewAssignee}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {dialogMessageSnackBar}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AssigneeDialogBox;