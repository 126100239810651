import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import { Dialog } from '@mui/material';
import BaseLayout from './baselayout';
import AssigneeManager from './AssigneeManager';
import TasksTable from './subComponents/_calendarTasksTable';
import useStore from '../store/Store'

function Assignees() {

    const headerContent = "Assignees";
    const [assigneesData, updateAssigneesData] = useState([]);                                  // fetch assignees data
    const [tasks, setTasks] = useState([]);                                                     // fetch tasks data
    const [taskCount, setTaskCount] = useState({});                                             // count of tasks assigned to each assignee
    const [showTasksTable, setShowTasksTable] = useState(false);                                // show the TasksTable component
    const [selectedTasks, setSelectedTasks] = useState([]);                                     // tasks assigned to a specific assignee
    const [selectedAssignee, setSelectedAssignee] = useState(null);                             // selected assignee
    const storeAssigneesData = useStore(state => state.assignees);
    const storeTasksData = useStore(state => state.tasks);
    useEffect(() => {                                                                           // update the assigneesData state once the data is fetched
        if (storeAssigneesData) {
            updateAssigneesData(storeAssigneesData);
        }
    }, [storeAssigneesData]);

    useEffect(() => {                                                                           // update the tasks state once the data is fetched
        if (storeTasksData) {
            const filteredTasks = storeTasksData.filter(task => task.status !== 'Cn' && task.status !== 'Co');
            setTasks(filteredTasks);
        }
    }, [storeTasksData]);

    useEffect(() => {
        const count = assigneesData.reduce((acc, assignee) => {
            acc[assignee.name] = tasks.filter(task => task.assignee === assignee.id).length;
            return acc;
        }, {});

        // Count tasks with no assignee
        count['No Assignee'] = tasks.filter(task => task.assignee === null).length;
        setTaskCount(count);
    }, [assigneesData, tasks, storeAssigneesData, storeTasksData]);

    const handleAssigneeClick = (assignee) => {                                                 // filter tasks assigned to a specific assignee
        const assigneeTasks = tasks.filter(task => task.assignee === assignee.id);
        setSelectedAssignee(assignee);
        setSelectedTasks(assigneeTasks);
        setShowTasksTable(true);
    };

    useEffect(() => {                                                                           // update the selectedTasks once the selectedAssignee state is updated
        if (selectedAssignee) {
            const assigneeTasks = tasks.filter(task => task.assignee === selectedAssignee.id);
            setSelectedTasks(assigneeTasks);
        }
    }, [tasks, selectedAssignee]);

    const handleTaskUpdateFromTaskTable = (updatedTask) => {

        const taskIndex = tasks.findIndex(task => task.id === updatedTask.id);
        let newTasks;
        if (taskIndex !== -1) {                             // task exists, update it
            newTasks = [...tasks];
            newTasks[taskIndex] = updatedTask;
        } else {                                            // task doesn't exist, add it
            newTasks = [...tasks, updatedTask];
        }


        const filteredTasks = newTasks.filter(task => task.status !== 'Cn' && task.status !== 'Co');
        setTasks(filteredTasks);
    };

    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
    setOpen(true);
    };

    const handleClose = () => {
    setOpen(false);
    };
    
    return (
        <BaseLayout headerContent={headerContent}>
            <div>
                <Dialog 
                    open={open} 
                    onClose={handleClose} 
                    fullWidth 
                    maxWidth="md" 
                    PaperProps={{ style: { maxWidth: '90vw', maxHeight: '90vh', width: 'auto', height: 'auto' } }}
                    >
                    <AssigneeManager />
                </Dialog>
            </div>
            <div className="max-w-[1200px] mx-auto">
                <Grid container spacing={3}>
                    {[...assigneesData, { name: 'No Assignee', id: null }]
                        .sort((a, b) => {
                            if (a.name === 'No Assignee') return 1;
                            if (b.name === 'No Assignee') return -1;
                            return taskCount[b.name] - taskCount[a.name];
                        })
                        .map(assignee => (
                            taskCount[assignee.name] > 0 && (
                                <Grid item key={assignee.id || 'no-assignee'}>
                                    <Badge badgeContent={taskCount[assignee.name]} color="primary">
                                        <Chip label={assignee.name} onClick={() => handleAssigneeClick(assignee)} />
                                    </Badge>
                                </Grid>
                            )
                        ))}
                    <Grid item>
                        <Chip label="Manage Assignees" onClick={handleClickOpen} style={{backgroundColor: '#00CA72'}} />
                    </Grid>
                </Grid>
                {showTasksTable && <TasksTable tasks={selectedTasks} onTasksUpdate={handleTaskUpdateFromTaskTable} />}
            </div>
        </BaseLayout>
    );
}

export default Assignees;